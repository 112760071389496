import React, { useState } from 'react';
import {
IonCard,
IonContent,
IonHeader,
IonPage,
IonText,
IonTitle,
IonToolbar,
IonItem,
IonButton,
IonIcon,
IonCardTitle,
IonToggle,
IonLabel,
IonSelect,
IonSelectOption,
useIonRouter,
IonInput,
IonToast,
} from '@ionic/react';
import { home, book, warning, close, pencil, logOutOutline, search, add, trash, refresh } from 'ionicons/icons';
import { GlobalParams } from "./MainMenu";
import './Settings.css';
import { useParams } from 'react-router';
import { initializeApp } from 'firebase/app';
import { getFirestore, getDoc, setDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDYhxOq7St4SaA3VNIxmk-Nx2va0KAFBKM",
    authDomain: "yahtzee-score-card.firebaseapp.com",
    projectId: "yahtzee-score-card",
    storageBucket: "yahtzee-score-card.appspot.com",
    messagingSenderId: "924619441287",
    appId: "1:924619441287:web:d1cd7c7e068038202ae422"
};

const app = initializeApp(firebaseConfig);

const Settings: React.FC = () => {

    const nav = useIonRouter()
    const params = useParams<GlobalParams>()
    const db = getFirestore(app)

    const [forceLogin, setForceLogin] = useState<boolean>(false)
    const [hideLogin, setHideLogin] = useState<boolean>(true)

    const [confirmPasscode, setConfirmPasscode] = useState<string>("")
    const [changeName, setChangeName] = useState<string>("")
    const [changePasscode, setChangePasscode] = useState<string>("")
    const [changePasscodeConfirm, setChangePasscodeConfirm] = useState<string>("")

    const [passcodeError, setPasscodeError] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [changePasscodeError, setChangePasscodeError] = useState<boolean>(false)
    const [nameChangeConfirm, setNameChangeConfirm] = useState<boolean>(false)
    const [passcodeChangeConfirm, setPasscodeChangeConfirm] = useState<boolean>(false)
    const [invalidSearch, setInvalidSearch] = useState<boolean>(false)
    const [specialCharsError, setSpecialCharsError] = useState<boolean>(false)
    const [specialCharsErrorMsg, setSpecialCharsErrorMsg] = useState<string>("")

    const [hideAccountConfirm, setHideAccountConfirm] = useState<boolean>(false);
    const [hideAccountInfo, setHideAccountInfo] = useState<boolean>(true);
    const [hidePasscodeReset, setHidePasscodeReset] = useState<boolean>(true);
    const [hideEditName, setHideEditName] = useState<boolean>(true);

    const [hideAdminPannel, setHideAdminPannel] = useState<boolean>(true);
    const [hideCreateAccount, setHideCreateAccount] = useState<boolean>(true);
    const [hideViewAccount, setHideViewAccount] = useState<boolean>(true);
    const [hideAdminViewAccount, setHideAdminViewAccount] = useState<boolean>(true);

    const [nEntryID, setNEntryID] = useState<string>("")
    const [nName, setnName] = useState<string>("")
    const [nPasscode, setNPasscode] = useState<string>("")
    const [nToken, setNToken] = useState<string>("")
    const [creationConfirm, setCreationConfirm] = useState<boolean>(false)
    const [deleteCheck, setDeleteCheck] = useState<boolean>(false)
    const [deletionConfirm, setDeletionConfirm] = useState<boolean>(false)

    const [userSearch, setUserSearch] = useState<string>("")

    const [sEntryID, setSEntryID] = useState<string>("")
    const [sName, setSName] = useState<string>("")
    const [sPasscode, setSPasscode] = useState<string>("")
    const [sToken, setSToken] = useState<string>("")
    const [userPasscodeResetConfirm, setUserPasscodeResetConfirm] = useState<boolean>(false)

    let [aPasscode, setAInfoPasscode] = useState<string>("")
    let [aName, setAName] = useState<string>("")

    let [theme, setTheme] = useState<number>(NaN);
    let [viewChangelog, setViewChangelog] = useState<boolean>(true);
    let [viewChangelogSwitch, setViewChangelogSwitch] = useState<boolean>(false);

    async function confirmPass(entryID: string, passcode: string) {
        const docUserRef = doc(db, "users", entryID)
        const docUser = await getDoc(docUserRef)
        if (docUser.exists()) {
            const userPasscode = docUser.data().passcode
            if (passcode === userPasscode) {
                setAInfoPasscode(userPasscode)
                setAName(docUser.data().name)
                setHideAccountInfo(false)
                setHideAccountConfirm(true)
                if (entryID === "S2R06") {
                    setHideAdminPannel(false)
                }
            } else {
                setPasscodeError(true)
            }
        } else {
            setError(true)
        }
        setConfirmPasscode("")
    }

    async function updateName(entryID: string, name: string) {
        await updateDoc(doc(db, "users", entryID), {
            name: name
        })
        setNameChangeConfirm(true)
        setAName(name)
        setChangeName("")
        setForceLogin(true)
        setHideLogin(false)
        setHidePasscodeReset(true)
        setHideEditName(true)
        setHideAccountInfo(true)
        setHideAdminPannel(true)
        setHideCreateAccount(true)
        setHideViewAccount(true)
        setHideAdminViewAccount(true)
    }

    async function updatePasscode(entryID: string, passcode: string, passcodeConfirm: string) {
        if (passcode === passcodeConfirm) {
            await updateDoc(doc(db, "users", entryID), {
                passcode: passcode
            })
            setPasscodeChangeConfirm(true)
            setAInfoPasscode(passcode)
            setHidePasscodeReset(true)
            setChangePasscode("")
            setChangePasscodeConfirm("")
            setHideAccountInfo(false)
            if (entryID === "S2R06") {
                setHideAdminPannel(false)
            }
        } else {
            setChangePasscodeError(true)
        }
    }

    async function createAccount(entryID: string, name: string, passcode: string, token: string) {
        await setDoc(doc(db, "users", entryID), {
            entryID: entryID,
            name: name,
            passcode: passcode,
            token: token
        })
        await setDoc(doc(db, "users", entryID, "yahtzeeRecords", "totals"), {
            G1: 0,
            G2: 0,
            G3: 0,
            G4: 0,
            G5: 0,
            G6: 0,
            G7: 0,
            G8: 0,
            G9: 0,
            G10: 0,
            Auto: 0
        })
        await setDoc(doc(db, "users", entryID, "yahtzeeRecords", "saveDates"), {
            D1: "Never",
            D2: "Never",
            D3: "Never",
            D4: "Never",
            D5: "Never",
            D6: "Never",
            D7: "Never",
            D8: "Never",
            D9: "Never",
            D10: "Never",
            Auto: "Never"
        })
        setHideCreateAccount(true)
        setHideAccountInfo(false)
        if (params.entryID === "S2R06") {
            setHideAdminPannel(false)
        }
        setNPasscode("")
        setNToken("")
        setCreationConfirm(true)
    }

    async function searchAccount(entryID: string) {
        const docSearchUserRef = doc(db, "users", entryID)
        const docSearchUser = await getDoc(docSearchUserRef)
        if (docSearchUser.exists()) {
            setSEntryID(docSearchUser.data().entryID)
            setSName(docSearchUser.data().name)
            setSPasscode(docSearchUser.data().passcode)
            setSToken(docSearchUser.data().token)
            setHideAdminViewAccount(false)
            setUserSearch("")
        } else {
            setInvalidSearch(true)
        }
    }

    async function resetPasscode(entryID: string) {
        await updateDoc(doc(db, "users", entryID), {
            passcode: "password"
        })
        setSPasscode("password")
        setUserPasscodeResetConfirm(true)
    }

    async function deleteUser(entryID: string) {
        if (entryID !== "S2R06") {
            await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "totals"))
            await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "saveDates"))
            const docG1 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G1"))
            if (docG1.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G1"))
            }
            const docG2 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G2"))
            if (docG2.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G2"))
            }
            const docG3 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G3"))
            if (docG3.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G3"))
            }
            const docG4 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G4"))
            if (docG4.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G4"))
            }
            const docG5 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G5"))
            if (docG5.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G5"))
            }
            const docG6 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G6"))
            if (docG6.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G6"))
            }
            const docG7 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G7"))
            if (docG7.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G7"))
            }
            const docG8 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G8"))
            if (docG8.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G8"))
            }
            const docG9 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G9"))
            if (docG9.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G9"))
            }
            const docG10 = await getDoc(doc(db, "users", entryID, "yahtzeeRecords", "G10"))
            if (docG10.exists()) {
                await deleteDoc(doc(db, "users", entryID, "yahtzeeRecords", "G10"))
            }
            await deleteDoc(doc(db, "users", entryID))
            setHideAdminViewAccount(true)
            setDeletionConfirm(true)
        }
    }

    function specialCharsCheck(str: string) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        return specialChars.test(str)
    }

    var inputPasscodeColour: string
    var lockProcceedButton: boolean

    if (confirmPasscode === "") {
        inputPasscodeColour = "medium"
        lockProcceedButton = true
    } else {
        inputPasscodeColour = "success"
        lockProcceedButton = false
    }

    var changeNameColour: string
    var lockChangeNameButton: boolean

    if (changeName === "") {
        changeNameColour = "medium"
        lockChangeNameButton = true
    } else {
        changeNameColour = "success"
        lockChangeNameButton = false
    }

    var changePasscodeColour: string
    var changePasscodeColourConfirm: string
    var changePasscodeButtonColour: string
    var lockChangePasscodeButton: boolean

    if (changePasscode === "") {
        changePasscodeColour = "medium"
    } else {
        changePasscodeColour = "success"
    }

    if (changePasscodeConfirm === "") {
        changePasscodeColourConfirm = "medium"
    } else {
        changePasscodeColourConfirm = "success"
    }

    if (changePasscode === "" || changePasscodeConfirm === "") {
        changePasscodeButtonColour = "medium"
        lockChangePasscodeButton = true
    } else {
        changePasscodeButtonColour = "success"
        lockChangePasscodeButton = false
    }

    var accountConfirm: boolean

    if (forceLogin === true || hideAccountConfirm === true) {
        accountConfirm = true
    } else {
        accountConfirm = false
    }

    var newEntryIDColour: string
    var newNameColour: string
    var newPasscodeColour: string
    var newTokenColour: string
    var createAccountButtonColour: string
    var lockCreateButton: boolean

    if (nEntryID === "") {
        newEntryIDColour = "medium"
    } else {
        newEntryIDColour = "success"
    }

    if (nName === "") {
        newNameColour = "medium"
    } else {
        newNameColour = "success"
    }

    if (nPasscode === "") {
        newPasscodeColour = "medium"
    } else {
        newPasscodeColour = "success"
    }

    if (nToken === "") {
        newTokenColour = "medium"
    } else {
        newTokenColour = "success"
    }

    if (nEntryID === "" || nName === "" || nPasscode === "" || nToken === "") {
        createAccountButtonColour = "medium"
        lockCreateButton = true
    } else {
        createAccountButtonColour = "success"
        lockCreateButton = false
    }

    var searchColour: string
    var searchButtonColour: string
    var lockSearchButton: boolean

    if (userSearch === "") {
        searchColour = "medium"
        searchButtonColour = "medium"
        lockSearchButton = true
    } else if (userSearch.length < 5) {
        searchColour = "success"
        searchButtonColour = "medium"
        lockSearchButton = true
    } else {
        searchColour = "success"
        searchButtonColour = "success"
        lockSearchButton = false
    }

return (
    <IonPage id="settings-page">
    <IonHeader>
        <IonToolbar>
            <IonButton slot="start" fill="clear" color="success" hidden={forceLogin} onClick={() => {
                nav.push(`/MainMenu/${params.entryID}/${params.name}/${params.token}`)
            }}>
                <IonIcon icon={home}></IonIcon>
            </IonButton>
            <IonTitle className="settings-page-header">
                Settings
            </IonTitle>
            <IonTitle className="build-number">
                Build <IonText color="danger">2.6.1 DEV</IonText>
            </IonTitle>
            <IonButton slot="end" fill="clear" color="success" hidden={forceLogin} onClick={() => {
                nav.push(`/Records/${params.entryID}/${params.name}/${params.token}`)
            }}>
                <IonIcon icon={book}></IonIcon>
            </IonButton>
        </IonToolbar>
    </IonHeader>
    <IonContent fullscreen>

        {
            /*
            * Account settings tabs start here
            */
        }

        <IonCard hidden={hideLogin}>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">Please Re-Login:</IonText>
                </IonCardTitle>
                <IonButton slot="end" fill="clear" color="warning" href="/Home">
                    <IonIcon icon={logOutOutline}></IonIcon>
                </IonButton>
            </IonItem>
        </IonCard>
        <IonCard hidden={accountConfirm}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">{params.name + "'s "}</IonText><IonText color="dark">Account</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonText>
                    Enter your passcode to view your account:
                </IonText>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonInput
                    value={confirmPasscode}
                    type="password"
                    inputMode="text" 
                    maxlength={50}
                    placeholder="Enter Your Passcode"
                    color={inputPasscodeColour}
                    className=""
                    spellCheck={false}
                    required={true}
                    onIonChange={e => setConfirmPasscode(e.detail.value!)}
                />
            </IonItem>
            <IonItem lines="none" className="settings-content">
                <IonButton slot="end" fill="clear" color={inputPasscodeColour} disabled={lockProcceedButton} onClick={() => {
                        confirmPass(params.entryID, confirmPasscode)
                    }}>
                    Confirm Passcode
                </IonButton>
            </IonItem>
        </IonCard>

        <IonCard hidden={hideAccountInfo}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">Account Info</IonText>
                </IonCardTitle>
                <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                        setHideAccountInfo(true)
                        setHideAdminPannel(true)
                        setHideAccountConfirm(false)
                    }}>
                    <IonIcon icon={close}/>
                </IonButton>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonLabel>
                    <IonText>{"Account ID: "}</IonText><IonText color="primary">{params.entryID}</IonText>
                </IonLabel>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonLabel>
                    <IonText>{"Name: "}</IonText><IonText color="primary">{aName}</IonText>
                </IonLabel>
                <IonButton slot="end" fill="clear" color="success" onClick={() => {
                        setHideEditName(false)
                        setChangeName(params.name)
                        setHideAccountInfo(true)
                        setHideAdminPannel(true)
                    }}>
                    <IonIcon icon={pencil}/>
                </IonButton>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonLabel>
                    <IonText>{"Passcode: "}</IonText><IonText color="primary">{aPasscode}</IonText>
                </IonLabel>
                <IonButton slot="end" fill="clear" color="success" onClick={() => {
                        setHidePasscodeReset(false)
                        setHideAccountInfo(true)
                        setHideAdminPannel(true)
                    }}>
                    <IonIcon icon={pencil}/>
                </IonButton>
            </IonItem>
            <IonItem lines="none" className="settings-content">
                <IonLabel>
                    <IonText>{"Token: "}</IonText><IonText color="primary">{params.token}</IonText>
                </IonLabel>
            </IonItem>
        </IonCard>

        <IonCard hidden={hideEditName}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">Change You're Name</IonText>
                </IonCardTitle>
                <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                        setHideEditName(true)
                        setHideAccountInfo(false)
                        if (params.entryID === "S2R06") {
                            setHideAdminPannel(false)
                        }
                    }}>
                    <IonIcon icon={close}/>
                </IonButton>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonLabel>
                    <IonText>{"Current Name: "}</IonText><IonText color="primary">{aName}</IonText>
                </IonLabel>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonInput
                    value={changeName}
                    type="text"
                    inputMode="text" 
                    maxlength={20}
                    placeholder="Enter Your New Name"
                    color={changeNameColour}
                    className=""
                    spellCheck={false}
                    required={true}
                    onIonChange={e => setChangeName(e.detail.value!)}
                />
            </IonItem>
            <IonItem lines="none" className="settings-content">
                <IonButton slot="end" fill="clear" color={changeNameColour} disabled={lockChangeNameButton} onClick={() => {
                        if (specialCharsCheck(changeName)) {
                            setSpecialCharsErrorMsg(`You're name cannot contain special characters/symbols. Please remove any special characters from: ${changeName}.`)
                            setSpecialCharsError(true)
                        } else {
                            updateName(params.entryID, changeName)
                        }
                    }}>
                    Update Name
                </IonButton>
            </IonItem>
        </IonCard>

        <IonCard hidden={hidePasscodeReset}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">Change You're Passcode</IonText>
                </IonCardTitle>
                <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                        setHidePasscodeReset(true)
                        setHideAccountInfo(false)
                        if (params.entryID === "S2R06") {
                            setHideAdminPannel(false)
                        }
                    }}>
                    <IonIcon icon={close}/>
                </IonButton>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonLabel>
                    <IonText>{"Current Passcode: "}</IonText><IonText color="primary">{aPasscode}</IonText>
                </IonLabel>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonInput
                    value={changePasscode}
                    type="password"
                    inputMode="text" 
                    maxlength={50}
                    placeholder="Enter Your New Passcode"
                    color={changePasscodeColour}
                    className=""
                    spellCheck={false}
                    required={true}
                    onIonChange={e => setChangePasscode(e.detail.value!)}
                />
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonInput
                    value={changePasscodeConfirm}
                    type="password"
                    inputMode="text" 
                    maxlength={50}
                    placeholder="Confirm Your New Passcode"
                    color={changePasscodeColourConfirm}
                    className=""
                    spellCheck={false}
                    required={true}
                    onIonChange={e => setChangePasscodeConfirm(e.detail.value!)}
                />
            </IonItem>
            <IonItem lines="none" className="settings-content">
                <IonButton slot="end" fill="clear" color={changePasscodeButtonColour} disabled={lockChangePasscodeButton} onClick={() => {
                        updatePasscode(params.entryID, changePasscode, changePasscodeConfirm)
                    }}>
                    Update Passcode
                </IonButton>
            </IonItem>
        </IonCard>

        <IonCard hidden={hideAdminPannel}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">System Admin Pannel</IonText>
                </IonCardTitle>
                <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                        setHideAdminPannel(true)
                        setHideAccountInfo(true)
                        setHideAccountConfirm(false)
                    }}>
                    <IonIcon icon={close}/>
                </IonButton>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonLabel>
                    <IonText color="dark">Create An Account</IonText>
                </IonLabel>
                <IonButton slot="end" fill="clear" color="success" onClick={() => {
                    setHideAdminPannel(true)
                    setHideAccountInfo(true)
                    setHideCreateAccount(false)
                }}>
                    <IonIcon icon={add}/>
                </IonButton>
            </IonItem>
            <IonItem lines="none" className="settings-content">
                <IonLabel>
                    <IonText color="dark">View An Account</IonText>
                </IonLabel>
                <IonButton slot="end" fill="clear" color="success" onClick={() => {
                    setHideAdminPannel(true)
                    setHideAccountInfo(true)
                    setHideViewAccount(false)
                }}>
                    <IonIcon icon={search}/>
                </IonButton>
            </IonItem>
        </IonCard>

        <IonCard hidden={hideCreateAccount}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">Create A New Account</IonText>
                </IonCardTitle>
                <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                        setHideCreateAccount(true)
                        setHideAccountInfo(false)
                        if (params.entryID === "S2R06") {
                            setHideAdminPannel(false)
                        }
                    }}>
                    <IonIcon icon={close}/>
                </IonButton>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonLabel>
                    <IonText color="dark">Please fill in all the details below:</IonText>
                </IonLabel>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonText color="primary">Ensure all details are correct before continueing!</IonText>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonInput
                    value={nEntryID}
                    type="text"
                    inputMode="text" 
                    maxlength={5}
                    placeholder="Entry An Entry ID"
                    color={newEntryIDColour}
                    className=""
                    spellCheck={false}
                    required={true}
                    onIonChange={e => setNEntryID(e.detail.value!)}
                />
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonInput
                    value={nName}
                    type="text"
                    inputMode="text" 
                    maxlength={20}
                    placeholder="Entry The Persons Name"
                    color={newNameColour}
                    className=""
                    spellCheck={false}
                    required={true}
                    onIonChange={e => setnName(e.detail.value!)}
                />
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonInput
                    value={nPasscode}
                    type="password"
                    inputMode="text" 
                    maxlength={50}
                    placeholder="Entry A Passcode"
                    color={newPasscodeColour}
                    className=""
                    spellCheck={false}
                    required={true}
                    onIonChange={e => setNPasscode(e.detail.value!)}
                />
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonInput
                    value={nToken}
                    type="text"
                    inputMode="text" 
                    maxlength={100}
                    placeholder="Entry A Token"
                    color={newTokenColour}
                    className=""
                    spellCheck={false}
                    required={true}
                    onIonChange={e => setNToken(e.detail.value!)}
                />
            </IonItem>
            <IonItem lines="none" className="settings-content">
                <IonButton slot="end" fill="clear" color={createAccountButtonColour} disabled={lockCreateButton} onClick={() => {
                    if (specialCharsCheck(nName) || specialCharsCheck(nToken)) {
                        setSpecialCharsErrorMsg(`You're Name and Token cannot contain special characters.`)
                        setSpecialCharsError(true)
                    } else {
                        createAccount(nEntryID, nName, nPasscode, nToken)
                    }
                }}>
                    Create Account
                </IonButton>
            </IonItem>
        </IonCard>

        <IonCard hidden={hideViewAccount}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">View An Account</IonText>
                </IonCardTitle>
                <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                        setHideAccountInfo(false)
                        setHideViewAccount(true)
                        setHideAdminViewAccount(true)
                        setUserSearch("")
                        if (params.entryID === "S2R06") {
                            setHideAdminPannel(false)
                        }
                    }}>
                    <IonIcon icon={close}/>
                </IonButton>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonInput
                    value={userSearch}
                    type="text"
                    inputMode="text" 
                    maxlength={5}
                    placeholder="Entry An Entry ID"
                    color={searchColour}
                    className=""
                    spellCheck={false}
                    required={true}
                    onIonChange={e => setUserSearch(e.detail.value!)}
                />
            </IonItem>
            <IonItem lines="none" className="settings-content">
                <IonButton slot="end" fill="clear" color={searchButtonColour} disabled={lockSearchButton} onClick={() => {
                    searchAccount(userSearch)
                }}>
                    <IonIcon icon={search}/>
                </IonButton>
            </IonItem>
        </IonCard>

        <IonCard hidden={hideAdminViewAccount}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonLabel>
                        <IonText color="dark">Account: </IonText><IonText color="primary">{sEntryID}</IonText>
                    </IonLabel>
                </IonCardTitle>
                <IonButton slot="end" fill="clear" color="danger" onClick={() => {
                    setDeleteCheck(true)
                }}>
                    <IonIcon icon={trash}/>
                </IonButton>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonLabel>
                    <IonText color="dark">Name: </IonText><IonText color="primary">{sName}</IonText>
                </IonLabel>
            </IonItem>
            <IonItem lines="full" className="settings-content">
                <IonLabel>
                    <IonText color="dark">Passcode: </IonText><IonText color="primary">{sPasscode}</IonText>
                </IonLabel>
                <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                    resetPasscode(sEntryID)
                }}>
                    <IonIcon icon={refresh}/>
                </IonButton>
            </IonItem>
            <IonItem lines="none" className="settings-content">
                <IonLabel>
                    <IonText color="dark">Token: </IonText><IonText color="primary">{sToken}</IonText>
                </IonLabel>
            </IonItem>
        </IonCard>

        {
            /*
            ! Theme Settings tab is here
            */
        }

        <IonCard hidden={true} disabled={true}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">Theme Settings</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="selection-setting">
                <IonLabel>
                    App Theme:
                </IonLabel>
                <IonSelect interface="alert" slot="end" placeholder="Dark" disabled={true} onIonChange={(e: any) => {
                    setTheme(parseInt(e.detail.value!))
                    theme = parseInt(e.detail.value!)
                    if (theme === 1) {
                        document.body.setAttribute("colour-theme", "light")
                    } else if (theme === 2) {
                        console.log("This theme does not exist yet.")
                    } else if (theme === 3) {
                        console.log("This theme does not exist yet.")
                    } else {
                        console.log("This theme does not exist yet.")
                    }
                }}>
                    <IonSelectOption value="1">Dark</IonSelectOption>
                    <IonSelectOption value="2">Light</IonSelectOption>
                    <IonSelectOption value="3">Dark Blue</IonSelectOption>
                    <IonSelectOption value="4">Light</IonSelectOption>
                </IonSelect>
            </IonItem>
        </IonCard>

        {
            /*
            * Change Log sections start here
            */
        }

        <IonCard hidden={forceLogin}>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">View Changelog?</IonText>
                </IonCardTitle>
                <IonToggle slot="end" color="success" checked={viewChangelogSwitch} onIonChange={() => {
                    if(viewChangelogSwitch === false){
                        setViewChangelogSwitch(true)
                        setViewChangelog(false)
                    } else {
                        setViewChangelogSwitch(false)
                        setViewChangelog(true)
                    }
                }}></IonToggle>
            </IonItem>
        </IonCard>

        <IonCard hidden={viewChangelog}>
            <IonItem lines="full">
                <IonCardTitle className="settings-header">
                    <IonText color="dark">App Changelog</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="full" className="selection-setting">
                <IonLabel>
                    Current App Build: <IonText color="danger">2.6.1 - Development</IonText>
                </IonLabel>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="warning">Build 2.6.1 - Tweaks / Bug Fixes</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Made the Yahtzee Score Card upper bonus text & current upper score text turn back to grey and display a new message if you haven't achieved you're bonus by the time you've finished the upper section of the score card.
                    <div/>
                    - Added a warning dialogue box to the 'Auto Save Load' button.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 2.6.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Added "Auto-Save" system to the Yahtzee score card which saves your game after every score input.
                    <div/>
                    - Added a setting to disable Auto-Save.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="warning">Build 2.5.3 - Tweaks / Bug Fixes</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Fixed password entry boxes so they now hide the password your typing.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="warning">Build 2.5.2 - Tweaks / Bug Fixes</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Fixed color of "total game score" text on the Yahtzee score card.
                    <div/>
                    - Fixed current date saving system so the app correctly logs and shows the time and date of each of your game saves.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="warning">Build 2.5.1 - Tweaks / Bug Fixes</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Prevented users from entering special characters in their user names to prevent the app from crashing upon logging in.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 2.5.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Renamed "Settings & Records" to "Records".
                    <div></div>
                    - Created a new "Settings" page and moved the "App Theme" setting & "Change Log" section over to it.
                    <div></div>
                    - Migrated login system to use the database.
                    <div></div>
                    - Turned the "Super Mario Yahtzee" score card into a general Yahtzee score card.
                    <div></div>
                    - Re-added the Standard Yahtzee score card as a toggleable option on the Yahtzee Score Card.
                    <div></div>
                    - Implimented the ability to save up to 10 score card records, allowing you to save you best scores and look back at them later through the "records" page.
                    <div></div>
                    - Added ability to delete the saved data slots individually.
                    <div></div>
                    - Enabled users to view and edit their account details from the "Settings" page.
                    <div></div>
                    - Implimented the System Admin Pannel.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 2.4.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Tweaked the app navigation system and page headers.
                    <div></div>
                    - Improved the security system to help prevent you you bypassing the login system.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 2.3.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Alligned the total score numbers of each card section to the right so there in line with all the individual score numbers.
                    <div></div>
                    - Put the total game score at the top of the card as well as the buttom.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 2.2.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Added the login screen for web build security.
                    <div></div>
                    - Created the web build of the app.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 2.1.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Added the "par counter" setting to the upper score section of the score card(s).
                    <div></div>
                    - Added toggle switch to the change log section of the settings & records page.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="secondary">Build 2.0.0 - Core Update</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Re-designed the Main Menu.
                    <div></div>
                    - Re-designed the Super Mario score card.
                    <div></div>
                    - Added the base foundations for a score saving system.
                    <div></div>
                    - Locked the score card "reset" button behind a toggle lock to ensure the card cannot accidentally be reset mid-game.
                    <div></div>
                    - Updated the bonus Yahtzee system to use checkboxes instead of selection boxes and locked the bonus Yahtzee's behind the regular Yahtzee.
                    <div></div>
                    - Turned the "Change Log" page into  "Settings & Records" page.
                    <div></div>
                    - Temporarily removed the Standard Yahtzee score card.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 1.4.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Updated all score card text to use "=" instead of "|".
                    <div></div>
                    - Added a toggle to the Super Mario Yahtzee score card to toggle the names between Super Mario Names and Standard Yahtzee Names.
                    <div></div>
                    - Compacted some of the item names text to reduce the need for a new line upon a score being entered.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 1.3.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Added the "Standard Score Card".
                    <div></div>
                    - Made the bonus Yahtzee images dynamic, switching between a tick and cross respectively.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 1.2.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Removed the "Update" button on the score card(s).
                    <div></div>
                    - Made the final scores calculate automatically.
                    <div></div>
                    - Tweaked the dark theme to darken the button colors on the main menu.
                    <div></div>
                    - Added "Change Log" page.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="warning">Build 1.1.1 - Tweaks / Bug Fixes</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Fixed the Math on 5 Sixes.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="primary">Build 1.1.0</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Moved the Yahtzee Score Cards to their own app and redesigned the Home Page.
                    <div></div>
                    - Started adding the light yellow Theme.
                    <div></div>
                    - Tweaked the dark theme to match the upcoming light yellow theme.
                    <div></div>
                    - Automated the "Upper Bonus" system so it no longer requires a user input.
                </IonText>
            </IonItem>
            <IonItem lines="none">
                <IonCardTitle className="settings-header">
                    <IonText color="secondary">Build 1.0.0 - Core Update</IonText>
                </IonCardTitle>
            </IonItem>
            <IonItem lines="none" className="changelog-setting">
                <IonText>
                    - Initial Build Which Included:
                    <div></div>
                    - - Main Menu.
                    <div></div>
                    - - Super Mario Yahtzee Score Card.
                </IonText>
            </IonItem>
        </IonCard>

        {
            /*
            * Pop-up alerts / warnings start here
            */
        }

        <IonToast
            isOpen={passcodeError}
            onDidPresent={() => {}}
            onDidDismiss={() => setPasscodeError(false)}
            message="The passcode you entered is incorrect."
            icon={warning}
            color="danger"
            position="top"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={error}
            onDidPresent={() => {}}
            onDidDismiss={() => setError(false)}
            message="An unacspected error occoured."
            icon={warning}
            color="danger"
            position="top"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={changePasscodeError}
            onDidPresent={() => {}}
            onDidDismiss={() => setChangePasscodeError(false)}
            message="The two passcodes you have entered are not the same."
            icon={warning}
            color="danger"
            position="top"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={nameChangeConfirm}
            onDidPresent={() => {}}
            onDidDismiss={() => {setNameChangeConfirm(false)}}
            message="You're name has been updated! You will need to re-login in order for this change to take effect throughout the app."
            color="success"
            position="middle"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={passcodeChangeConfirm}
            onDidPresent={() => {}}
            onDidDismiss={() => setPasscodeChangeConfirm(false)}
            message="You're passcode has been updated!"
            color="success"
            position="top"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={creationConfirm}
            onDidPresent={() => {}}
            onDidDismiss={() => {
                setCreationConfirm(false)
                setNEntryID("")
                setnName("")
            }}
            message={`The account: ${nEntryID} (${nName}) has been created!`}
            color="success"
            position="top"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={invalidSearch}
            onDidPresent={() => {}}
            onDidDismiss={() => {
                setInvalidSearch(false)
                setUserSearch("")
            }}
            message={`The user ${userSearch} does not exist.`}
            icon={warning}
            color="danger"
            position="top"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={userPasscodeResetConfirm}
            onDidPresent={() => {}}
            onDidDismiss={() => setUserPasscodeResetConfirm(false)}
            message={`The passcode for ${sEntryID} has been reset to "password".`}
            color="success"
            position="top"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={deletionConfirm}
            onDidPresent={() => {}}
            onDidDismiss={() => setDeletionConfirm(false)}
            message={`The account ${sEntryID} has been deleted.`}
            color="success"
            position="top"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={deleteCheck}
            onDidPresent={() => {}}
            onDidDismiss={() => {
                setDeleteCheck(false)
            }}
            message={`Are you sure you want to delete the user: ${sEntryID} (${sName})?`}
            icon={warning}
            color="danger"
            position="top"
            buttons={[
                {
                    text: "Yes",
                    handler: () => {
                        deleteUser(sEntryID)
                    }
                },
                {
                    text: "No",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />
        <IonToast
            isOpen={specialCharsError}
            onDidPresent={() => {}}
            onDidDismiss={() => {
                setSpecialCharsError(false)
            }}
            message={`${specialCharsErrorMsg}`}
            icon={warning}
            color="danger"
            position="top"
            buttons={[
                {
                    text: "Ok",
                    role: "cancel",
                    handler: () => {}
                }
            ]}
        />

    </IonContent>
</IonPage>
);
};

export default Settings;
