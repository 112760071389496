import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Home from './pages/Home';
import MainMenu from './pages/MainMenu';
import YahtzeeScoreCard from "./pages/Yahtzee/YahtzeeScoreCard"
import Records from './pages/Records';
import Settings from './pages/Settings';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/" exact={true}>
          <Redirect to="/home" />
        </Route>
        <Route path="/home" exact={true}>
          <Home />
        </Route>
        <Route path="/MainMenu/:entryID/:name/:token" exact={true}>
          <MainMenu />
        </Route>
        <Route path="/Yahtzee/YahtzeeScoreCard/:entryID/:name/:token" exact={true}>
          <YahtzeeScoreCard />
        </Route>
        <Route path="/Records/:entryID/:name/:token" exact={true}>
          <Records />
        </Route>
        <Route path="/Settings/:entryID/:name/:token" exact={true}>
          <Settings />
        </Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
