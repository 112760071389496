import React, { useState } from "react";
import {
    IonButton,
    IonText,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonImg,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonToggle,
    IonIcon,
    IonCardTitle,
    IonCheckbox,
    IonAvatar,
    IonLabel,
    useIonRouter,
    IonToast,
} from "@ionic/react";
import { arrowBack, refresh, warning, caretDown, close, save, trash, download } from "ionicons/icons"
import './YahtzeeScoreCard.css';
import { GlobalParams } from "../MainMenu";
import { useParams } from "react-router";
import { initializeApp } from 'firebase/app';
import { getFirestore, getDoc, setDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDYhxOq7St4SaA3VNIxmk-Nx2va0KAFBKM",
    authDomain: "yahtzee-score-card.firebaseapp.com",
    projectId: "yahtzee-score-card",
    storageBucket: "yahtzee-score-card.appspot.com",
    messagingSenderId: "924619441287",
    appId: "1:924619441287:web:d1cd7c7e068038202ae422"
};

const app = initializeApp(firebaseConfig);

const YahtzeeScoreCard: React.FC = () => {

    const nav = useIonRouter()
    const params = useParams<GlobalParams>()
    const db = getFirestore(app)

    const [hideSaveCard, setHideSaveCard] = useState<boolean>(true)
    const [hideSaveArrow, setHideSaveArrow] = useState<boolean>(false)

    const [G1, setG1] = useState<number>(NaN)
    const [D1, setD1] = useState<string>("Never")
    const [G2, setG2] = useState<number>(NaN)
    const [D2, setD2] = useState<string>("Never")
    const [G3, setG3] = useState<number>(NaN)
    const [D3, setD3] = useState<string>("Never")
    const [G4, setG4] = useState<number>(NaN)
    const [D4, setD4] = useState<string>("Never")
    const [G5, setG5] = useState<number>(NaN)
    const [D5, setD5] = useState<string>("Never")
    const [G6, setG6] = useState<number>(NaN)
    const [D6, setD6] = useState<string>("Never")
    const [G7, setG7] = useState<number>(NaN)
    const [D7, setD7] = useState<string>("Never")
    const [G8, setG8] = useState<number>(NaN)
    const [D8, setD8] = useState<string>("Never")
    const [G9, setG9] = useState<number>(NaN)
    const [D9, setD9] = useState<string>("Never")
    const [G10, setG10] = useState<number>(NaN)
    const [D10, setD10] = useState<string>("Never")
    const [AutoSave, setAutoSave] = useState<number>(NaN)
    const [AutoTime, setAutoTime] = useState<string>("Never")

    const [saveconfirmation, setSaveConfirmation] = useState<boolean>(false)
    const [loadConfirmation, setLoadConfirmation] = useState<boolean>(false)
    const [deleteConfirm, setDeleteConfirm] = useState<boolean>(false)
    const [autoSaveLoadWarning, setAutoSaveLoadWarning] = useState<boolean>(false)
    const [dataError, setDataError] = useState<boolean>(false)
    const [deleteError, setDeleteError] = useState<boolean>(false)

    const [onePH, setOnePH] = useState<string>("Select")
    const [twoPH, setTwoPH] = useState<string>("Select")
    const [threePH, setThreePH] = useState<string>("Select")
    const [fourPH, setFourPH] = useState<string>("Select")
    const [fivePH, setFivePH] = useState<string>("Select")
    const [sixPH, setSixPH] = useState<string>("Select")
    const [threeKindPH, setThreeKindPH] = useState<string>("Select")
    const [fourKindPH, setFourKindPH] = useState<string>("Select")
    const [fullHousePH, setFullHousePH] = useState<string>("Select")
    const [smallStraightPH, setSmallStraightPH] = useState<string>("Select")
    const [largeStraightPH, setLargeStraightPH] = useState<string>("Select")
    const [yahtzeePH, setYahtzeePH] = useState<string>("Select")
    const [chancePH, setChancePH] = useState<string>("Select")

    let [ones, setOnes] = useState<number>(0);
    let [twos, setTwos] = useState<number>(0);
    let [threes, setThrees] = useState<number>(0);
    let [fours, setFours] = useState<number>(0);
    let [fives, setFives] = useState<number>(0);
    let [sixes, setSixes] = useState<number>(0);

    let [par, setPar] = useState<number>(0);
    let [parOne, setParOne] = useState<number>(0);
    let [parTwo, setParTwo] = useState<number>(0);
    let [parThree, setParThree] = useState<number>(0);
    let [parFour, setParFour] = useState<number>(0);
    let [parFive, setParFive] = useState<number>(0);
    let [parSix, setParSix] = useState<number>(0);

    let [threeKind, setThreeKind] = useState<number>(0);
    let [fourKind, setFourKind] = useState<number>(0);
    let [fullHouse, setFullHouse] = useState<number>(0);
    let [smallStraight, setSmallStraight] = useState<number>(0);
    let [largeStraight, setLargeStraight] = useState<number>(0);
    let [yahtzee, setYahtzee] = useState<number>(0);
    let [chance, setChance] = useState<number>(0);

    let [yahtzeeBonusOne, setYahtzeeBonusOne] = useState<number>(0);
    let [yahtzeeBonusTwo, setYahtzeeBonusTwo] = useState<number>(0);
    let [yahtzeeBonusThree, setYahtzeeBonusThree] = useState<number>(0);

    let [bonusOneAchieved, setBonusOneAchieved] = useState<boolean>(false);
    let [bonusTwoAchieved, setBonusTwoAchieved] = useState<boolean>(false);
    let [bonusThreeAchieved, setBonusThreeAchieved] = useState<boolean>(false);

    let [bonusOneLock, setBonusOneLock] = useState<boolean>(true);
    let [bonusTwoLock, setBonusTwoLock] = useState<boolean>(true);
    let [bonusThreeLock, setBonusThreeLock] = useState<boolean>(true);

    let [upperScore, setUpperScore] = useState<number>(0);
    let [finalUpperScore, setFinalUpperScore] = useState<number>(0);
    let [lowerScore, setLowerScore] = useState<number>(0);
    let [bonusScore, setBonusScore] = useState<number>(0);
    let [finalScore, setFinalScore] = useState<number>(0);

    let [upperScoreText, setUpperScoreText] = useState<boolean>(false);
    let [standardAssets, setStandardAssets] = useState<boolean>(false);

    let [resetCard, setResetCard] = useState<boolean>(false);
    let [parCounter, setParCounter] = useState<boolean>(true);
    let [parCounterSwitch, setParCounterSwitch] = useState<boolean>(false);
    let [disableAutoSave, setDisableAutoSave] = useState<boolean>(false)

    function update(){
        setUpperScore(ones + twos + threes + fours + fives + sixes)
        upperScore = ones + twos + threes + fours + fives + sixes
        if (upperScore >= 63) {
            setFinalUpperScore(ones + twos + threes + fours + fives + sixes + 35)
            finalUpperScore = ones + twos + threes + fours + fives + sixes + 35
        } else {
            setFinalUpperScore(ones + twos + threes + fours + fives + sixes)
            finalUpperScore = ones + twos + threes + fours + fives + sixes
        }
        setLowerScore(threeKind + fourKind + fullHouse + smallStraight + largeStraight + yahtzee + chance)
        lowerScore = threeKind + fourKind + fullHouse + smallStraight + largeStraight + yahtzee + chance
        setBonusScore(yahtzeeBonusOne + yahtzeeBonusTwo + yahtzeeBonusThree)
        bonusScore = yahtzeeBonusOne + yahtzeeBonusTwo + yahtzeeBonusThree
        if (upperScore >= 63) {
            setFinalScore(ones + twos + threes + fours + fives + sixes + threeKind + fourKind + fullHouse + smallStraight + largeStraight + yahtzee + chance + yahtzeeBonusOne + yahtzeeBonusTwo + yahtzeeBonusThree + 35)
            finalScore = ones + twos + threes + fours + fives + sixes + threeKind + fourKind + fullHouse + smallStraight + largeStraight + yahtzee + chance + yahtzeeBonusOne + yahtzeeBonusTwo + yahtzeeBonusThree + 35
        } else {
            setFinalScore(ones + twos + threes + fours + fives + sixes + threeKind + fourKind + fullHouse + smallStraight + largeStraight + yahtzee + chance + yahtzeeBonusOne + yahtzeeBonusTwo + yahtzeeBonusThree)
            finalScore = ones + twos + threes + fours + fives + sixes + threeKind + fourKind + fullHouse + smallStraight + largeStraight + yahtzee + chance + yahtzeeBonusOne + yahtzeeBonusTwo + yahtzeeBonusThree
        }
        if (disableAutoSave === false) {
            saveData(params.entryID, "yahtzeeRecords", "AutoSave")
        }
    }

    async function retrieveData(entryID: string, gameType: string) {
        const docUserRef = doc(db, "users", entryID, gameType, "totals")
        const docUser = await getDoc(docUserRef)
        if (!docUser.exists()) {
            setDataError(true)
            return
        }
        const docDatesRef = doc(db, "users", entryID, gameType, "saveDates")
        const docDate = await getDoc(docDatesRef)
        if (!docDate.exists()) {
            setDataError(true)
            return
        }
        setG1(docUser.data().G1)
        setG2(docUser.data().G2)
        setG3(docUser.data().G3)
        setG4(docUser.data().G4)
        setG5(docUser.data().G5)
        setG6(docUser.data().G6)
        setG7(docUser.data().G7)
        setG8(docUser.data().G8)
        setG9(docUser.data().G9)
        setG10(docUser.data().G10)
        setAutoSave(docUser.data().Auto)
        setD1(docDate.data().D1)
        setD2(docDate.data().D2)
        setD3(docDate.data().D3)
        setD4(docDate.data().D4)
        setD5(docDate.data().D5)
        setD6(docDate.data().D6)
        setD7(docDate.data().D7)
        setD8(docDate.data().D8)
        setD9(docDate.data().D9)
        setD10(docDate.data().D10)
        setAutoTime(docDate.data().Auto)
        setHideSaveCard(false)
        setHideSaveArrow(true)
    }

    async function loadAutoSave(entryID: string, gameType: string) {
        const docAutoSaveRef = doc(db, "users", entryID, gameType, "AutoSave")
        const docAutoSave = await getDoc(docAutoSaveRef)
        if (!docAutoSave.exists()) {
            setDataError(true)
            return
        }
        //
        setOnes(docAutoSave.data().ones)
        if (docAutoSave.data().ones !== 0) {setOnePH(docAutoSave.data().ones)}
        setTwos(docAutoSave.data().twos)
        if (docAutoSave.data().twos !== 0) {setTwoPH(docAutoSave.data().twos)}
        setThrees(docAutoSave.data().threes)
        if (docAutoSave.data().threes !== 0) {setThreePH(docAutoSave.data().threes)}
        setFours(docAutoSave.data().fours)
        if (docAutoSave.data().fours !== 0) {setFourPH(docAutoSave.data().fours)}
        setFives(docAutoSave.data().fives)
        if (docAutoSave.data().fives !== 0) {setFivePH(docAutoSave.data().fives)}
        setSixes(docAutoSave.data().sixes)
        if (docAutoSave.data().sixes !== 0) {setSixPH(docAutoSave.data().sixes)}
        setPar(docAutoSave.data().par)
        setUpperScore(docAutoSave.data().baseUpperAmount)
        setFinalUpperScore(docAutoSave.data().upperScore)
        //
        setThreeKind(docAutoSave.data().threeKind)
        if (docAutoSave.data().threeKind !== 0) {setThreeKindPH(docAutoSave.data().threeKind)}
        setFourKind(docAutoSave.data().fourKind)
        if (docAutoSave.data().fourKind !== 0) {setFourKindPH(docAutoSave.data().fourKind)}
        setFullHouse(docAutoSave.data().fullHouse)
        if (docAutoSave.data().fullHouse !== 0) {setFullHousePH(docAutoSave.data().fullHouse)}
        setSmallStraight(docAutoSave.data().smallStraight)
        if (docAutoSave.data().smallStraight !== 0) {setSmallStraightPH(docAutoSave.data().smallStraight)}
        setLargeStraight(docAutoSave.data().largeStraight)
        if (docAutoSave.data().largeStraight !== 0) {setLargeStraightPH(docAutoSave.data().largeStraight)}
        setYahtzee(docAutoSave.data().yahtzee)
        if (docAutoSave.data().yahtzee !== 0) {setYahtzeePH(docAutoSave.data().yahtzee)}
        setChance(docAutoSave.data().chance)
        if (docAutoSave.data().chance !== 0) {setChancePH(docAutoSave.data().chance)}
        setLowerScore(docAutoSave.data().lowerScore)
        //
        setBonusScore(docAutoSave.data().bonusScore)
        setFinalScore(docAutoSave.data().finalScore)
        //
        if (docAutoSave.data().yahtzee === 50) {
            setBonusOneLock(false)
        } else {
            setBonusOneLock(true)
        }
        if (docAutoSave.data().BY1 === 100) {
            setYahtzeeBonusOne(100)
            yahtzeeBonusOne = 100
            setBonusOneAchieved(true)
            setBonusTwoLock(false)
        } else {
            setYahtzeeBonusOne(0)
            yahtzeeBonusOne = 0
            setBonusOneAchieved(false)
            setBonusTwoLock(true)
        }
        if (docAutoSave.data().BY2 === 100) {
            setYahtzeeBonusTwo(100)
            yahtzeeBonusTwo = 100
            setBonusTwoAchieved(true)
            setBonusTwoLock(false)
        } else {
            setYahtzeeBonusTwo(0)
            yahtzeeBonusTwo = 0
            setBonusTwoAchieved(false)
            setBonusTwoLock(true)
        }
        if (docAutoSave.data().BY3 === 100) {
            setYahtzeeBonusThree(100)
            yahtzeeBonusThree = 100
            setBonusThreeAchieved(true)
        } else {
            setYahtzeeBonusThree(0)
            yahtzeeBonusThree = 0
            setBonusThreeAchieved(false)
        }
        setHideSaveCard(true)
        setHideSaveArrow(false)
        setLoadConfirmation(true)
    }

    async function saveData(entryID: string, gameType: string, game: string) {
        await setDoc(doc(db, "users", entryID, gameType, game), {
            upperScore: finalUpperScore,
            ones: ones,
            twos: twos,
            threes: threes,
            fours: fours,
            fives: fives,
            sixes: sixes,
            bonus: bonusAmount,
            lowerScore: lowerScore,
            threeKind: threeKind,
            fourKind: fourKind,
            fullHouse: fullHouse,
            smallStraight: smallStraight,
            largeStraight: largeStraight,
            yahtzee: yahtzee,
            chance: chance,
            bonusScore: bonusScore,
            BY1: yahtzeeBonusOne,
            BY2: yahtzeeBonusTwo,
            BY3: yahtzeeBonusThree,
            finalScore: finalScore
        })
        const today = new Date()
        const date: string = String(today.getDate()).padStart(2, "0") + "/" + String(today.getMonth() + 1).padStart(2, "0") + "/" + today.getFullYear() + " - " + String(today.getHours()).padStart(2, "0") + ":" + String(today.getMinutes()).padStart(2, "0")
        if (game === "G1") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G1: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D1: date
            })
        }
        if (game === "G2") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G2: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D2: date
            })
        }
        if (game === "G3") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G3: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D3: date
            })
        }
        if (game === "G4") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G4: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D4: date
            })
        }
        if (game === "G5") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G5: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D5: date
            })
        }
        if (game === "G6") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G6: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D6: date
            })
        }
        if (game === "G7") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G7: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D7: date
            })
        }
        if (game === "G8") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G8: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D8: date
            })
        }
        if (game === "G9") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G9: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D9: date
            })
        }
        if (game === "G10") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G10: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D10: date
            })
        }
        if (game === "AutoSave") {
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                Auto: finalScore
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                Auto: date
            })
            await updateDoc(doc(db, "users", entryID, gameType, "AutoSave"), {
                baseUpperAmount: upperScore,
                par: par
            })
        }
        setHideSaveCard(true)
        setHideSaveArrow(false)
        if (game !== "AutoSave") {
            setSaveConfirmation(true)
        }
    }

    async function deleteData(entryID: string, gameType: string, game: string) {
        if (game === "G1") {
            const docG1 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG1.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G1: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D1: "Never"
            })
            setDeleteConfirm(true)
        }
        if (game === "G2") {
            const docG2 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG2.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G2: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D2: "Never"
            })
            setDeleteConfirm(true)
        }
        if (game === "G3") {
            const docG3 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG3.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G3: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D3: "Never"
            })
            setDeleteConfirm(true)
        }
        if (game === "G4") {
            const docG4 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG4.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G4: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D4: "Never"
            })
            setDeleteConfirm(true)
        }
        if (game === "G5") {
            const docG5 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG5.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G5: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D5: "Never"
            })
            setDeleteConfirm(true)
        }
        if (game === "G6") {
            const docG6 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG6.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G6: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D6: "Never"
            })
            setDeleteConfirm(true)
        }
        if (game === "G7") {
            const docG7 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG7.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G7: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D7: "Never"
            })
            setDeleteConfirm(true)
        }
        if (game === "G8") {
            const docG8 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG8.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G8: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D8: "Never"
            })
            setDeleteConfirm(true)
        }
        if (game === "G9") {
            const docG9 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG9.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G9: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D9: "Never"
            })
            setDeleteConfirm(true)
        }
        if (game === "G10") {
            const docG10 = await getDoc(doc(db, "users", entryID, gameType, game))
            if (!docG10.exists()) {
                setDeleteError(true)
                return
            }
            await deleteDoc(doc(db, "users", entryID, gameType, game))
            await updateDoc(doc(db, "users", entryID, gameType, "totals"), {
                G10: 0
            })
            await updateDoc(doc(db, "users", entryID, gameType, "saveDates"), {
                D10: "Never"
            })
            setDeleteConfirm(true)
        }
        setHideSaveCard(true)
        setHideSaveArrow(false)
    }

    let saveArrowButtonColour: string

    if (hideSaveArrow === true) {
        saveArrowButtonColour = "medium"
    } else {
        saveArrowButtonColour = "success"
    }

    let upperScoreColor: string

    if (upperScore === 0) {
        upperScoreColor = "medium"
    } else if (upperScore >= 63) {
        upperScoreColor = "success"
    } else if ((ones > 0 && twos > 0 && threes > 0 && fours > 0 && fives > 0 && sixes > 0)) {
        upperScoreColor = "medium"
    } else {
        upperScoreColor = "warning"
    }

    let achievedBonus: string
    let bonusAmount: string
    let bonusColor: string

    if (upperScore >= 63) {
        achievedBonus = "Bonus Achieved!"
        bonusAmount = "+35"
        bonusColor = "success"
    } else if (ones > 0 && twos > 0 && threes > 0 && fours > 0 && fives > 0 && sixes > 0) {
        achievedBonus = "Bonus Not Achieved"
        bonusAmount = "+0"
        bonusColor = "medium"
    } else if (upperScore > 0) {
        achievedBonus = "Bonus Not Yet Achieved!"
        bonusAmount = "+0"
        bonusColor = "warning"
    } else {
        achievedBonus = "Bonus Not Started"
        bonusAmount = "+0"
        bonusColor = "medium"
    }

    let bonusImageOne: string

    if (yahtzeeBonusOne === 100) {
        bonusImageOne = "/assets/images/standard/Bonus.png"
    } else {
        bonusImageOne = "/assets/images/standard/BonusFail.png"
    }

    let bonusImageTwo: string

    if (yahtzeeBonusTwo === 100) {
        bonusImageTwo = "/assets/images/standard/Bonus.png"
    } else {
        bonusImageTwo = "/assets/images/standard/BonusFail.png"
    }

    let bonusImageThree: string

    if (yahtzeeBonusThree === 100) {
        bonusImageThree = "/assets/images/standard/Bonus.png"
    } else {
        bonusImageThree = "/assets/images/standard/BonusFail.png"
    }

    let onesText: string
    let twosText: string
    let threesText: string
    let foursText: string
    let fivesText: string
    let sixesText: string

    if (upperScoreText === false) {
        onesText = "Coins:"
        twosText = "Mushrooms:"
        threesText = "Fire Flowers:"
        foursText = "Yoshi Eggs:"
        fivesText = "Stars:"
        sixesText = "Koopa Shells:"
    } else {
        onesText = "Ones:"
        twosText = "Twos:"
        threesText = "Threes:"
        foursText = "Fours:"
        fivesText = "Fives:"
        sixesText = "Sixes:"
    }

    let titleText: string
    let cardImage: string

    let onesImage: string
    let twosImage: string
    let threesImage: string
    let foursImage: string
    let fivesImage: string
    let sixesImage: string

    let threeKindImage: string
    let fourKindImage: string
    let fullHouseImage: string
    let smallStraightImage: string
    let largeStraightImage: string
    let yahtzeeImage: string
    let changeImage: string

    if (standardAssets === false) {
        titleText = "Super Mario Yahtzee"
        cardImage = "/assets/images/mario/SuperMarioLetters.png"
        onesImage = "/assets/images/mario/DiceOneCoin.png"
        twosImage = "/assets/images/mario/DiceTwoMushroom.png"
        threesImage = "/assets/images/mario/DiceThreeFireFlower.png"
        foursImage = "/assets/images/mario/DiceFourYoshiEgg.png"
        fivesImage = "/assets/images/mario/DiceFiveStar.png"
        sixesImage = "/assets/images/mario/DiceSixKoopaShell.png"
        threeKindImage = "/assets/images/mario/ThreeOfAKind.png"
        fourKindImage = "/assets/images/mario/FourOfAKind.png"
        fullHouseImage = "/assets/images/mario/FullHouse.png"
        smallStraightImage = "/assets/images/mario/LowStraight.png"
        largeStraightImage = "/assets/images/mario/HighStraight.png"
        yahtzeeImage = "/assets/images/mario/Yahtzee.png"
        changeImage = "/assets/images/mario/Chance.png"
    } else {
        titleText = "Standard Yahtzee"
        cardImage = "/assets/images/standard/YahtzeeLetters.png"
        onesImage = "/assets/images/standard/Ones.png"
        twosImage = "/assets/images/standard/Twos.png"
        threesImage = "/assets/images/standard/Threes.png"
        foursImage = "/assets/images/standard/Fours.png"
        fivesImage = "/assets/images/standard/Fives.png"
        sixesImage = "/assets/images/standard/Sixes.png"
        threeKindImage = "/assets/images/standard/ThreeKind.png"
        fourKindImage = "/assets/images/standard/FourKind.png"
        fullHouseImage = "/assets/images/standard/FullHouse.png"
        smallStraightImage = "/assets/images/standard/Small.png"
        largeStraightImage = "/assets/images/standard/Large.png"
        yahtzeeImage = "/assets/images/standard/Yahtzee.png"
        changeImage = "/assets/images/standard/Chance.png"
    }

    return (
        <IonPage id="Yahtzee-Score-Card">
            <IonHeader>
                <IonToolbar>
                    <IonButton slot="start" fill="clear" color="success" onClick={() => {
                        nav.push(`/MainMenu/${params.entryID}/${params.name}/${params.token}`)
                    }}>
                        <IonIcon icon={arrowBack}></IonIcon>
                    </IonButton>
                    <IonTitle className="yahtzee-score-card-page-header">
                        {titleText}
                    </IonTitle>
                    <IonButton slot="end" fill="clear" color="warning" onClick={() => {setResetCard(true)}}>
                        <IonIcon icon={refresh}></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>

                <IonCard>
                    <IonImg src={cardImage}/>
                </IonCard>

                <IonCard>
                    <IonItem lines="none">
                        <IonCardTitle className="score-card-heading-text" color="dark">
                            Game Score:
                        </IonCardTitle>
                        <IonCardTitle className="score-card-heading-text" slot="end" color="primary">
                            {finalScore}
                        </IonCardTitle>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem lines="full">
                        <IonCardTitle className="settings-header">
                            <IonText color="dark">Card Settings</IonText>
                        </IonCardTitle>
                    </IonItem>
                    <IonItem lines="inset">
                        <IonText color="dark">Standard Naming?</IonText>
                        <IonToggle slot="end" color="success" checked={upperScoreText} onIonChange={e => setUpperScoreText(e.detail.checked)}/>
                    </IonItem>
                    <IonItem lines="inset">
                        <IonText color="dark">Standard Assets?</IonText>
                        <IonToggle slot="end" color="success" checked={standardAssets} onIonChange={e => setStandardAssets(e.detail.checked)}/>
                    </IonItem>
                    <IonItem lines="inset">
                        <IonText color="dark">Show Upper Par Counter?</IonText>
                        <IonToggle slot="end" color="success" checked={parCounterSwitch} onIonChange={(e: any) => {
                            if(parCounterSwitch === false){
                                setParCounterSwitch(true)
                                setParCounter(false)
                            } else {
                                setParCounterSwitch(false)
                                setParCounter(true)
                            }
                        }}/>
                    </IonItem>
                    <IonItem lines="inset">
                        <IonText color="dark">Disable Auto-Save?</IonText>
                        <IonToggle slot="end" color="danger" checked={disableAutoSave} onIonChange={e => setDisableAutoSave(e.detail.checked)}/>
                    </IonItem>
                    <IonItem lines="none">
                        <IonText color="dark">Save Current Scores?</IonText>
                        <IonButton slot="end" fill="clear" color={saveArrowButtonColour} disabled={hideSaveArrow} onClick={() => {
                            retrieveData(params.entryID, "yahtzeeRecords")
                        }}>
                            <IonIcon icon={caretDown}/>
                        </IonButton>
                    </IonItem>
                </IonCard>

                <IonCard hidden={hideSaveCard}>
                    <IonItem lines="full">
                        <IonCardTitle className="settings-header">
                            <IonText color="dark">Save Card?</IonText>
                        </IonCardTitle>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            setHideSaveCard(true)
                            setHideSaveArrow(false)
                        }}>
                            <IonIcon icon={close}/>
                        </IonButton>
                    </IonItem>
                    <IonItem hidden={disableAutoSave} lines="full">
                        <IonLabel>
                            <IonText color="secondary">Auto Save</IonText><IonText color="dark"> | Score: </IonText><IonText color="secondary">{AutoSave}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="secondary">{AutoTime}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            setAutoSaveLoadWarning(true)
                        }}>
                            <IonIcon icon={download}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="none">
                        <IonText color="dark">Select a slot to save you're current scores to.</IonText>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">You're current score is: </IonText><IonText color="primary">{finalScore}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#1</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G1}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D1}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G1")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G1")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#2</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G2}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D2}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G2")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G2")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#3</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G3}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D3}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G3")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G3")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#4</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G4}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D4}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G4")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G4")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#5</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G5}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D5}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G5")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G5")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#6</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G6}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D6}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G6")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G6")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#7</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G7}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D7}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G7")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G7")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#8</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G8}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D8}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G8")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G8")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="full">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#9</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G9}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D9}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G9")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G9")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="none">
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#10</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{G10}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D10}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color="success" onClick={() => {
                            saveData(params.entryID, "yahtzeeRecords", "G10")
                        }}>
                            <IonIcon icon={save}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            deleteData(params.entryID, "yahtzeeRecords", "G10")
                        }}>
                            <IonIcon icon={trash}/>
                        </IonButton>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem lines="full">
                        <IonCardTitle className="score-card-heading-text">
                            <IonText color="dark">Upper Scores</IonText>
                        </IonCardTitle>
                        <IonCardTitle hidden={parCounter} slot="end" className="score-card-heading-text">
                            <IonText color="dark">Par: </IonText><IonText color="primary">{par}</IonText>
                        </IonCardTitle>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={onesImage}/>
                        </IonAvatar>
                        <IonLabel>
                            {onesText}
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={onePH} onIonChange={(e: any) => {
                            if(ones === 5 || ones === 4){
                                par = par - parOne
                                setPar(par)
                            };
                            if(ones === 2 || ones === 1){
                                par = par + parOne
                                setPar(par)
                            };
                            setOnes(parseInt(e.detail.value!));
                            ones = parseInt(e.detail.value!);
                            if(ones === 5){
                                parOne = 2
                                setParOne(2)
                                par = par + parOne
                                setPar(par)
                            }else if(ones === 4){
                                parOne = 1
                                setParOne(1)
                                par = par + parOne
                                setPar(par)
                            }else if(ones === 3){
                                parOne = 0
                                setParOne(0)
                            }else if(ones === 2){
                                parOne = 1
                                setParOne(1)
                                par = par - parOne
                                setPar(par)
                            }else if(ones === 1){
                                parOne = 2
                                setParOne(2)
                                par = par - parOne
                                setPar(par)
                            }else{
                                parOne = 0
                                setParOne(0)
                            };
                            update();
                        }}>
                            <IonSelectOption value="1">1</IonSelectOption>
                            <IonSelectOption value="2">2</IonSelectOption>
                            <IonSelectOption value="3">3</IonSelectOption>
                            <IonSelectOption value="4">4</IonSelectOption>
                            <IonSelectOption value="5">5</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={twosImage}/>
                        </IonAvatar>
                        <IonLabel>
                            {twosText}
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={twoPH} onIonChange={(e: any) => {
                            if(twos === 10 || twos === 8){
                                par = par - parTwo
                                setPar(par)
                            };
                            if(twos === 4 || twos === 2){
                                par = par + parTwo
                                setPar(par)
                            };
                            setTwos(parseInt(e.detail.value!));
                            twos = parseInt(e.detail.value!);
                            if(twos === 10){
                                parTwo = 4
                                setParTwo(4)
                                par = par + parTwo
                                setPar(par)
                            }else if(twos === 8){
                                parTwo = 2
                                setParTwo(2)
                                par = par + parTwo
                                setPar(par)
                            }else if(twos === 6){
                                parTwo = 0
                                setParTwo(0)
                            }else if(twos === 4){
                                parTwo = 2
                                setParTwo(2)
                                par = par - parTwo
                                setPar(par)
                            }else if(twos === 2){
                                parTwo = 4
                                setParTwo(4)
                                par = par - parTwo
                                setPar(par)
                            }else{
                                parTwo = 0
                                setParTwo(0)
                            };
                            update();
                        }}>
                            <IonSelectOption value="2">2</IonSelectOption>
                            <IonSelectOption value="4">4</IonSelectOption>
                            <IonSelectOption value="6">6</IonSelectOption>
                            <IonSelectOption value="8">8</IonSelectOption>
                            <IonSelectOption value="10">10</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={threesImage}/>
                        </IonAvatar>
                        <IonLabel>
                            {threesText}
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={threePH} onIonChange={(e: any) => {
                            if(threes === 15 || threes === 12){
                                par = par - parThree
                                setPar(par)
                            };
                            if(threes === 6 || threes === 3){
                                par = par + parThree
                                setPar(par)
                            };
                            setThrees(parseInt(e.detail.value!));
                            threes = parseInt(e.detail.value!);
                            if(threes === 15){
                                parThree = 6
                                setParThree(6)
                                par = par + parThree
                                setPar(par)
                            }else if(threes === 12){
                                parThree = 3
                                setParThree(3)
                                par = par + parThree
                                setPar(par)
                            }else if(threes === 9){
                                parThree = 0
                                setParThree(0)
                            }else if(threes === 6){
                                parThree = 3
                                setParThree(3)
                                par = par - parThree
                                setPar(par)
                            }else if(threes === 3){
                                parThree = 6
                                setParThree(6)
                                par = par - parThree
                                setPar(par)
                            }else{
                                parThree = 0
                                setParThree(0)
                            };
                            update();
                        }}>
                            <IonSelectOption value="3">3</IonSelectOption>
                            <IonSelectOption value="6">6</IonSelectOption>
                            <IonSelectOption value="9">9</IonSelectOption>
                            <IonSelectOption value="12">12</IonSelectOption>
                            <IonSelectOption value="15">15</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={foursImage}/>
                        </IonAvatar>
                        <IonLabel>
                            {foursText}
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={fourPH} onIonChange={(e: any) => {
                            if(fours === 20 || fours === 16){
                                par = par - parFour
                                setPar(par)
                            };
                            if(fours === 8 || fours === 4){
                                par = par + parFour
                                setPar(par)
                            };
                            setFours(parseInt(e.detail.value!));
                            fours = parseInt(e.detail.value!);
                            if(fours === 20){
                                parFour = 8
                                setParFour(8)
                                par = par + parFour
                                setPar(par)
                            }else if(fours === 16){
                                parFour = 4
                                setParFour(4)
                                par = par + parFour
                                setPar(par)
                            }else if(fours === 12){
                                parFour = 0
                                setParFour(0)
                            }else if(fours === 8){
                                parFour = 4
                                setParFour(4)
                                par = par - parFour
                                setPar(par)
                            }else if(fours === 4){
                                parFour = 8
                                setParFour(8)
                                par = par - parFour
                                setPar(par)
                            }else{
                                parFour = 0
                                setParFour(0)
                            };
                            update();
                        }}>
                            <IonSelectOption value="4">4</IonSelectOption>
                            <IonSelectOption value="8">8</IonSelectOption>
                            <IonSelectOption value="12">12</IonSelectOption>
                            <IonSelectOption value="16">16</IonSelectOption>
                            <IonSelectOption value="20">20</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={fivesImage}/>
                        </IonAvatar>
                        <IonLabel>
                            {fivesText}
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={fivePH} onIonChange={(e: any) => {
                            if(fives === 25 || fives === 20){
                                par = par - parFive
                                setPar(par)
                            };
                            if(fives === 10 || fives === 5){
                                par = par + parFive
                                setPar(par)
                            };
                            setFives(parseInt(e.detail.value!));
                            fives = parseInt(e.detail.value!);
                            if(fives === 25){
                                parFive = 10
                                setParFive(10)
                                par = par + parFive
                                setPar(par)
                            }else if(fives === 20){
                                parFive = 5
                                setParFive(5)
                                par = par + parFive
                                setPar(par)
                            }else if(fives === 15){
                                parFive = 0
                                setParFive(0)
                            }else if(fives === 10){
                                parFive = 5
                                setParFive(5)
                                par = par - parFive
                                setPar(par)
                            }else if(fives === 5){
                                parFive = 10
                                setParFive(10)
                                par = par - parFive
                                setPar(par)
                            }else{
                                parFive = 0
                                setParFive(0)
                            };
                            update();
                        }}>
                            <IonSelectOption value="5">5</IonSelectOption>
                            <IonSelectOption value="10">10</IonSelectOption>
                            <IonSelectOption value="15">15</IonSelectOption>
                            <IonSelectOption value="20">20</IonSelectOption>
                            <IonSelectOption value="25">25</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={sixesImage}/>
                        </IonAvatar>
                        <IonLabel>
                            {sixesText}
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={sixPH} onIonChange={(e: any) => {
                            if(sixes === 30 || sixes === 24){
                                par = par - parSix
                                setPar(par)
                            };
                            if(sixes === 12 || sixes === 6){
                                par = par + parSix
                                setPar(par)
                            };
                            setSixes(parseInt(e.detail.value!));
                            sixes = parseInt(e.detail.value!);
                            if(sixes === 30){
                                parSix = 12
                                setParSix(12)
                                par = par + parSix
                                setPar(par)
                            }else if(sixes === 24){
                                parSix = 6
                                setParSix(6)
                                par = par + parSix
                                setPar(par)
                            }else if(sixes === 18){
                                parSix = 0
                                setParSix(0)
                            }else if(sixes === 12){
                                parSix = 6
                                setParSix(6)
                                par = par - parSix
                                setPar(par)
                            }else if(sixes === 6){
                                parSix = 12
                                setParSix(12)
                                par = par - parSix
                                setPar(par)
                            }else{
                                parSix = 0
                                setParSix(0)
                            };
                            update();
                        }}>
                            <IonSelectOption value="6">6</IonSelectOption>
                            <IonSelectOption value="12">12</IonSelectOption>
                            <IonSelectOption value="18">18</IonSelectOption>
                            <IonSelectOption value="24">24</IonSelectOption>
                            <IonSelectOption value="30">30</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonLabel>
                            Total Score:
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color={upperScoreColor}>{upperScore}</IonText> / 63
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonLabel color={bonusColor}>
                            {achievedBonus}
                        </IonLabel>
                        <IonLabel slot="end" color={bonusColor}>
                            {bonusAmount}
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="none" className="score-card-info-text">
                        <IonLabel>
                            Final Upper Score:
                        </IonLabel>
                        <IonLabel slot="end" color="primary">
                            {finalUpperScore}
                        </IonLabel>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem lines="full">
                        <IonCardTitle className="score-card-heading-text">
                            <IonText color="dark">Lower Scores</IonText>
                        </IonCardTitle>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={threeKindImage}/>
                        </IonAvatar>
                        <IonLabel>
                            3 of a Kind:
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={threeKindPH} onIonChange={(e: any) => {
                            setThreeKind(parseInt(e.detail.value!))
                            threeKind = parseInt(e.detail.value!)
                            update()
                        }}>
                            <IonSelectOption value="3">3</IonSelectOption>
                            <IonSelectOption value="4">4</IonSelectOption>
                            <IonSelectOption value="5">5</IonSelectOption>
                            <IonSelectOption value="6">6</IonSelectOption>
                            <IonSelectOption value="7">7</IonSelectOption>
                            <IonSelectOption value="8">8</IonSelectOption>
                            <IonSelectOption value="9">9</IonSelectOption>
                            <IonSelectOption value="10">10</IonSelectOption>
                            <IonSelectOption value="11">11</IonSelectOption>
                            <IonSelectOption value="12">12</IonSelectOption>
                            <IonSelectOption value="13">13</IonSelectOption>
                            <IonSelectOption value="14">14</IonSelectOption>
                            <IonSelectOption value="15">15</IonSelectOption>
                            <IonSelectOption value="16">16</IonSelectOption>
                            <IonSelectOption value="17">17</IonSelectOption>
                            <IonSelectOption value="18">18</IonSelectOption>
                            <IonSelectOption value="19">19</IonSelectOption>
                            <IonSelectOption value="20">20</IonSelectOption>
                            <IonSelectOption value="21">21</IonSelectOption>
                            <IonSelectOption value="22">22</IonSelectOption>
                            <IonSelectOption value="23">23</IonSelectOption>
                            <IonSelectOption value="24">24</IonSelectOption>
                            <IonSelectOption value="25">25</IonSelectOption>
                            <IonSelectOption value="26">26</IonSelectOption>
                            <IonSelectOption value="27">27</IonSelectOption>
                            <IonSelectOption value="28">28</IonSelectOption>
                            <IonSelectOption value="29">29</IonSelectOption>
                            <IonSelectOption value="30">30</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={fourKindImage}/>
                        </IonAvatar>
                        <IonLabel>
                            4 of a Kind:
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={fourKindPH} onIonChange={(e: any) => {
                            setFourKind(parseInt(e.detail.value!))
                            fourKind = parseInt(e.detail.value!)
                            update()
                        }}>
                            <IonSelectOption value="4">4</IonSelectOption>
                            <IonSelectOption value="5">5</IonSelectOption>
                            <IonSelectOption value="6">6</IonSelectOption>
                            <IonSelectOption value="7">7</IonSelectOption>
                            <IonSelectOption value="8">8</IonSelectOption>
                            <IonSelectOption value="9">9</IonSelectOption>
                            <IonSelectOption value="10">10</IonSelectOption>
                            <IonSelectOption value="11">11</IonSelectOption>
                            <IonSelectOption value="12">12</IonSelectOption>
                            <IonSelectOption value="13">13</IonSelectOption>
                            <IonSelectOption value="14">14</IonSelectOption>
                            <IonSelectOption value="15">15</IonSelectOption>
                            <IonSelectOption value="16">16</IonSelectOption>
                            <IonSelectOption value="17">17</IonSelectOption>
                            <IonSelectOption value="18">18</IonSelectOption>
                            <IonSelectOption value="19">19</IonSelectOption>
                            <IonSelectOption value="20">20</IonSelectOption>
                            <IonSelectOption value="21">21</IonSelectOption>
                            <IonSelectOption value="22">22</IonSelectOption>
                            <IonSelectOption value="23">23</IonSelectOption>
                            <IonSelectOption value="24">24</IonSelectOption>
                            <IonSelectOption value="25">25</IonSelectOption>
                            <IonSelectOption value="26">26</IonSelectOption>
                            <IonSelectOption value="27">27</IonSelectOption>
                            <IonSelectOption value="28">28</IonSelectOption>
                            <IonSelectOption value="29">29</IonSelectOption>
                            <IonSelectOption value="30">30</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={fullHouseImage}/>
                        </IonAvatar>
                        <IonLabel>
                            Full House:
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={fullHousePH} onIonChange={(e: any) => {
                            setFullHouse(parseInt(e.detail.value!))
                            fullHouse = parseInt(e.detail.value!)
                            update()
                        }}>
                            <IonSelectOption value="25">25</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={smallStraightImage}/>
                        </IonAvatar>
                        <IonLabel>
                            Small Straight:
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={smallStraightPH} onIonChange={(e: any) => {
                            setSmallStraight(parseInt(e.detail.value!))
                            smallStraight = parseInt(e.detail.value!)
                            update()
                        }}>
                            <IonSelectOption value="30">30</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={largeStraightImage}/>
                        </IonAvatar>
                        <IonLabel>
                            Large Straight:
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={largeStraightPH} onIonChange={(e: any) => {
                            setLargeStraight(parseInt(e.detail.value!))
                            largeStraight = parseInt(e.detail.value!)
                            update()
                        }}>
                            <IonSelectOption value="40">40</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={yahtzeeImage}/>
                        </IonAvatar>
                        <IonLabel>
                            Yahtzee:
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={yahtzeePH} onIonChange={(e:any) => {
                            setYahtzee(parseInt(e.detail.value!))
                            yahtzee = parseInt(e.detail.value!)
                            if (yahtzee === 50) {
                                setBonusOneLock(false)
                            } else {
                                setBonusOneLock(true)
                            }
                            update()
                        }}>
                            <IonSelectOption value="50">50</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={changeImage}/>
                        </IonAvatar>
                        <IonLabel>
                            Chance:
                        </IonLabel>
                        <IonSelect interface="alert" slot="end" placeholder={chancePH} onIonChange={(e: any) => {
                            setChance(parseInt(e.detail.value!))
                            chance = parseInt(e.detail.value!)
                            update()
                        }}>
                            <IonSelectOption value="1">1</IonSelectOption>
                            <IonSelectOption value="2">2</IonSelectOption>
                            <IonSelectOption value="3">3</IonSelectOption>
                            <IonSelectOption value="4">4</IonSelectOption>
                            <IonSelectOption value="5">5</IonSelectOption>
                            <IonSelectOption value="6">6</IonSelectOption>
                            <IonSelectOption value="7">7</IonSelectOption>
                            <IonSelectOption value="8">8</IonSelectOption>
                            <IonSelectOption value="9">9</IonSelectOption>
                            <IonSelectOption value="10">10</IonSelectOption>
                            <IonSelectOption value="11">11</IonSelectOption>
                            <IonSelectOption value="12">12</IonSelectOption>
                            <IonSelectOption value="13">13</IonSelectOption>
                            <IonSelectOption value="14">14</IonSelectOption>
                            <IonSelectOption value="15">15</IonSelectOption>
                            <IonSelectOption value="16">16</IonSelectOption>
                            <IonSelectOption value="17">17</IonSelectOption>
                            <IonSelectOption value="18">18</IonSelectOption>
                            <IonSelectOption value="19">19</IonSelectOption>
                            <IonSelectOption value="20">20</IonSelectOption>
                            <IonSelectOption value="21">21</IonSelectOption>
                            <IonSelectOption value="22">22</IonSelectOption>
                            <IonSelectOption value="23">23</IonSelectOption>
                            <IonSelectOption value="24">24</IonSelectOption>
                            <IonSelectOption value="25">25</IonSelectOption>
                            <IonSelectOption value="26">26</IonSelectOption>
                            <IonSelectOption value="27">27</IonSelectOption>
                            <IonSelectOption value="28">28</IonSelectOption>
                            <IonSelectOption value="29">29</IonSelectOption>
                            <IonSelectOption value="30">30</IonSelectOption>
                            <IonSelectOption value="0">N/A</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem lines="none" className="score-card-info-text">
                        <IonLabel>
                            Final Lower Score:
                        </IonLabel>
                        <IonLabel slot="end" color="primary">
                            {lowerScore}
                        </IonLabel>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem lines="full">
                        <IonCardTitle className="score-card-heading-text">
                            <IonText color="dark">Bonus Yahtzee's</IonText>
                        </IonCardTitle>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={bonusImageOne}/>
                        </IonAvatar>
                        <IonLabel>
                            Bonus 1:
                        </IonLabel>
                        <IonCheckbox color="success" slot="end" checked={bonusOneAchieved} disabled={bonusOneLock} onIonChange={(e: any) => {
                            if (bonusOneAchieved === false){
                                setYahtzeeBonusOne(100)
                                yahtzeeBonusOne = 100
                                setBonusOneAchieved(true)
                                setBonusTwoLock(false)
                            } else {
                                setYahtzeeBonusOne(0)
                                yahtzeeBonusOne = 0
                                setBonusOneAchieved(false)
                                setBonusTwoLock(true)
                            }
                            update()
                        }}>
                        </IonCheckbox>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={bonusImageTwo}/>
                        </IonAvatar>
                        <IonLabel>
                            Bonus 2:
                        </IonLabel>
                        <IonCheckbox color="success" slot="end" checked={bonusTwoAchieved} disabled={bonusTwoLock} onIonChange={(e: any) => {
                            if (bonusTwoAchieved === false){
                                setYahtzeeBonusTwo(100)
                                yahtzeeBonusTwo = 100
                                setBonusTwoAchieved(true)
                                setBonusThreeLock(false)
                            } else {
                                setYahtzeeBonusTwo(0)
                                yahtzeeBonusTwo = 0
                                setBonusTwoAchieved(false)
                                setBonusThreeLock(true)
                            }
                            update()
                        }}>
                        </IonCheckbox>
                    </IonItem>
                    <IonItem lines="full" className="score-card-info-text">
                        <IonAvatar slot="start">
                            <IonImg src={bonusImageThree}/>
                        </IonAvatar>
                        <IonLabel>
                            Bonus 3:
                        </IonLabel>
                        <IonCheckbox color="success" slot="end" checked={bonusThreeAchieved} disabled={bonusThreeLock} onIonChange={(e: any) => {
                            if (bonusThreeAchieved === false){
                                setYahtzeeBonusThree(100)
                                yahtzeeBonusThree = 100
                                setBonusThreeAchieved(true)
                            } else {
                                setYahtzeeBonusThree(0)
                                yahtzeeBonusThree = 0
                                setBonusThreeAchieved(false)
                            }
                            update()
                        }}>
                        </IonCheckbox>
                    </IonItem>
                    <IonItem lines="none" className="score-card-info-text">
                        <IonLabel>
                            Final Bonus Score:
                        </IonLabel>
                        <IonLabel slot="end" color="primary">
                            {bonusScore}
                        </IonLabel>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonItem lines="none">
                        <IonCardTitle className="score-card-heading-text" color="dark">
                            Game Score:
                        </IonCardTitle>
                        <IonCardTitle className="score-card-heading-text" slot="end" color="primary">
                            {finalScore}
                        </IonCardTitle>
                    </IonItem>
                </IonCard>

                {
                /*
                    * Pop-up alert / warning messages
                */
                }

                <IonToast
                    isOpen={resetCard}
                    onDidPresent={() => {}}
                    onDidDismiss={() => setResetCard(false)}
                    message="Are you sure you want to reset the score card?"
                    icon={warning}
                    color="danger"
                    position="middle"
                    buttons={[
                        {
                            text: "Reset",
                            handler: () => {window.location.reload()}
                        },
                        {
                            text: "Cancel",
                            role: "cancel",
                            handler: () => {}
                        }
                    ]}
                />
                <IonToast
                    isOpen={dataError}
                    onDidPresent={() => {}}
                    onDidDismiss={() => setDataError(false)}
                    message="An error occured! ID: 1DRE - There was an error while retrieving data."
                    icon={warning}
                    color="danger"
                    position="bottom"
                    buttons={[
                        {
                            text: "Ok",
                            role: "cancel",
                            handler: () => {}
                        }
                    ]}
                />
                <IonToast
                    isOpen={saveconfirmation}
                    onDidPresent={() => {}}
                    onDidDismiss={() => setSaveConfirmation(false)}
                    message="You're game scores were saved successfully!"
                    color="success"
                    position="top"
                    buttons={[
                        {
                            text: "Ok",
                            role: "cancel",
                            handler: () => {}
                        }
                    ]}
                />
                <IonToast
                    isOpen={loadConfirmation}
                    onDidPresent={() => {}}
                    onDidDismiss={() => setLoadConfirmation(false)}
                    message="You're previous game scores were loaded successfully!"
                    color="success"
                    position="top"
                    buttons={[
                        {
                            text: "Ok",
                            role: "cancel",
                            handler: () => {}
                        }
                    ]}
                />
                <IonToast
                    isOpen={deleteError}
                    onDidPresent={() => {}}
                    onDidDismiss={() => setDeleteError(false)}
                    message="There was no saved data to delete."
                    icon={warning}
                    color="danger"
                    position="bottom"
                    buttons={[
                        {
                            text: "Ok",
                            role: "cancel",
                            handler: () => {}
                        }
                    ]}
                />
                <IonToast
                    isOpen={deleteConfirm}
                    onDidPresent={() => {}}
                    onDidDismiss={() => setDeleteConfirm(false)}
                    message="Saved data was deleted."
                    color="success"
                    position="top"
                    buttons={[
                        {
                            text: "Ok",
                            role: "cancel",
                            handler: () => {}
                        }
                    ]}
                />
                <IonToast
                    isOpen={autoSaveLoadWarning}
                    onDidPresent={() => {}}
                    onDidDismiss={() => setAutoSaveLoadWarning(false)}
                    message="Are you sure you want to load the Auto Save scores? This will override you're current scores!"
                    icon={warning}
                    color="danger"
                    position="middle"
                    buttons={[
                        {
                            text: "Load",
                            handler: () => {loadAutoSave(params.entryID, "yahtzeeRecords")}
                        },
                        {
                            text: "Cancel",
                            role: "cancel",
                            handler: () => {}
                        }
                    ]}
                />
            </IonContent>
        </IonPage>
    );
};

export default YahtzeeScoreCard;