import React, { useState } from 'react';
import {
IonCard,
IonContent,
IonHeader,
IonPage,
IonText,
IonTitle,
IonToolbar,
IonItem,
IonButton,
IonIcon,
IonCardTitle,
IonLabel,
useIonRouter,
IonAvatar,
IonImg,
IonToast,
} from '@ionic/react';
import { settingsOutline, eye, close, caretDown, warning, home } from 'ionicons/icons';
import { GlobalParams } from "./MainMenu";
import './Records.css';
import { useParams } from 'react-router';
import { initializeApp } from 'firebase/app';
import { getFirestore, getDoc, doc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDYhxOq7St4SaA3VNIxmk-Nx2va0KAFBKM",
    authDomain: "yahtzee-score-card.firebaseapp.com",
    projectId: "yahtzee-score-card",
    storageBucket: "yahtzee-score-card.appspot.com",
    messagingSenderId: "924619441287",
    appId: "1:924619441287:web:d1cd7c7e068038202ae422"
};

const app = initializeApp(firebaseConfig);

const Records: React.FC = () => {

    const nav = useIonRouter()
    const params = useParams<GlobalParams>()
    const db = getFirestore(app)

    const [lockGameButton, setLockGameButton] = useState<boolean>(false)
    const [lockViewButton, setLockViewButton] = useState<boolean>(true)
    const [hideRecord, setHideRecord] = useState<boolean>(true)
    const [gameNum, setGameNum] = useState<string>("")

    const [hideYahtzeeRecords, setHideYahtzeeRecords] = useState<boolean>(true)
    const [hideYahtzeeRefresh, setHideYahtzeeRefresh] = useState<boolean>(false)
    const [hideYahtzeeClose, setHideYahtzeeClose] = useState<boolean>(true)
    const [YG1, setYG1] = useState<number>(NaN)
    const [YG2, setYG2] = useState<number>(NaN)
    const [YG3, setYG3] = useState<number>(NaN)
    const [YG4, setYG4] = useState<number>(NaN)
    const [YG5, setYG5] = useState<number>(NaN)
    const [YG6, setYG6] = useState<number>(NaN)
    const [YG7, setYG7] = useState<number>(NaN)
    const [YG8, setYG8] = useState<number>(NaN)
    const [YG9, setYG9] = useState<number>(NaN)
    const [YG10, setYG10] = useState<number>(NaN)
    const [D1, setD1] = useState<string>("Never")
    const [D2, setD2] = useState<string>("Never")
    const [D3, setD3] = useState<string>("Never")
    const [D4, setD4] = useState<string>("Never")
    const [D5, setD5] = useState<string>("Never")
    const [D6, setD6] = useState<string>("Never")
    const [D7, setD7] = useState<string>("Never")
    const [D8, setD8] = useState<string>("Never")
    const [D9, setD9] = useState<string>("Never")
    const [D10, setD10] = useState<string>("Never")
    const [date, setDate] = useState<string>("")

    const [upperScoreR, setUpperScoreR] = useState<number>(NaN)
    const [onesR, setOnesR] = useState<number>(NaN)
    const [twosR, setTwosR] = useState<number>(NaN)
    const [threesR, setThreesR] = useState<number>(NaN)
    const [foursR, setFoursR] = useState<number>(NaN)
    const [fivesR, setFivesR] = useState<number>(NaN)
    const [sixesR, setSixesR] = useState<number>(NaN)
    const [bonusR, setBonusR] = useState<string>("")

    const [lowerScoreR, setLowerScoreR] = useState<number>(NaN)
    const [threeKindR, setThreeKindR] = useState<number>(NaN)
    const [fourKindR, setFourKindR] = useState<number>(NaN)
    const [fullHouseR, setFullHouseR] = useState<number>(NaN)
    const [smallStraightR, setSmallStraightR] = useState<number>(NaN)
    const [largeStraightR, setLargeStraightR] = useState<number>(NaN)
    const [yahtzeeR, setYahtzeeR] = useState<number>(NaN)
    const [chanceR, setChanceR] = useState<number>(NaN)
    const [finalScoreR, setFinalScoreR] = useState<number>(NaN)

    const [bonusScoreR, setBonusScoreR] = useState<number>(NaN)
    const [bonusYOR, setBonusYOR] = useState<number>(NaN)
    const [bonusYTR, setBonusYTR] = useState<number>(NaN)
    const [bonusYThR, setBonusYThR] = useState<number>(NaN)

    const [documentError, setDocumentError] = useState<boolean>(false)

    async function retreiveRecords(entryID: string, gameType: string) {
        const docUserRef = doc(db, "users", entryID, gameType, "totals")
        const docUser = await getDoc(docUserRef)
        if (!docUser.exists()) {
            setDocumentError(true)
            return
        }
        const docDatesRef = doc(db, "users", entryID, gameType, "saveDates")
        const docDate = await getDoc(docDatesRef)
        if (!docDate.exists()) {
            setDocumentError(true)
            return
        }
        setYG1(docUser.data().G1)
        setYG2(docUser.data().G2)
        setYG3(docUser.data().G3)
        setYG4(docUser.data().G4)
        setYG5(docUser.data().G5)
        setYG6(docUser.data().G6)
        setYG7(docUser.data().G7)
        setYG8(docUser.data().G8)
        setYG9(docUser.data().G9)
        setYG10(docUser.data().G10)
        setD1(docDate.data().D1)
        setD2(docDate.data().D2)
        setD3(docDate.data().D3)
        setD4(docDate.data().D4)
        setD5(docDate.data().D5)
        setD6(docDate.data().D6)
        setD7(docDate.data().D7)
        setD8(docDate.data().D8)
        setD9(docDate.data().D9)
        setD10(docDate.data().D10)
        setLockViewButton(false)
        setHideYahtzeeRecords(false)
    }

    async function retrieveData(entryID: string, gameType: string, gameNum: string) {
        const docGameRef = doc(db, "users", entryID, gameType, gameNum)
        const docGame = await getDoc(docGameRef)
        if (!docGame.exists()) {
            setDocumentError(true)
            return
        }
        const docDateRef = doc(db, "users", entryID, gameType, "saveDates")
        const docDate = await getDoc(docDateRef)
        if (!docDate.exists()) {
            setDocumentError(true)
            return
        }
        setUpperScoreR(docGame.data().upperScore)
        setOnesR(docGame.data().ones)
        setTwosR(docGame.data().twos)
        setThreesR(docGame.data().threes)
        setFoursR(docGame.data().fours)
        setFivesR(docGame.data().fives)
        setSixesR(docGame.data().sixes)
        setBonusR(docGame.data().bonus)
        setLowerScoreR(docGame.data().lowerScore)
        setThreeKindR(docGame.data().threeKind)
        setFourKindR(docGame.data().fourKind)
        setFullHouseR(docGame.data().fullHouse)
        setSmallStraightR(docGame.data().smallStraight)
        setLargeStraightR(docGame.data().largeStraight)
        setYahtzeeR(docGame.data().yahtzee)
        setChanceR(docGame.data().chance)
        setFinalScoreR(docGame.data().finalScore)
        setBonusScoreR(docGame.data().bonusScore)
        setBonusYOR(docGame.data().BY1)
        setBonusYTR(docGame.data().BY2)
        setBonusYThR(docGame.data().BY3)
        if (gameNum === "G1") {
            setDate(docDate.data().D1)
        }
        if (gameNum === "G2") {
            setDate(docDate.data().D2)
        }
        if (gameNum === "G3") {
            setDate(docDate.data().D3)
        }
        if (gameNum === "G4") {
            setDate(docDate.data().D4)
        }
        if (gameNum === "G5") {
            setDate(docDate.data().D5)
        }
        if (gameNum === "G6") {
            setDate(docDate.data().D6)
        }
        if (gameNum === "G7") {
            setDate(docDate.data().D7)
        }
        if (gameNum === "G8") {
            setDate(docDate.data().D8)
        }
        if (gameNum === "G9") {
            setDate(docDate.data().D9)
        }
        if (gameNum === "G10") {
            setDate(docDate.data().D10)
        }
        setHideRecord(false)
        setHideYahtzeeRecords(true)
        setLockViewButton(true)
        setLockGameButton(true)
    }

    var viewButtonColour: string

    if (lockViewButton === true) {
        viewButtonColour = "medium"
    } else {
        viewButtonColour = "success"
    }

    var gameButtonColour: string

    if (lockGameButton === true) {
        gameButtonColour = "medium"
    } else {
        if (hideYahtzeeClose === false) {
            gameButtonColour = "warning"
        } else {
            gameButtonColour = "success"
        }
    }

    return (
        <IonPage id="records-page">
            <IonHeader>
                <IonToolbar>
                    <IonButton slot="start" fill="clear" color="success" onClick={() => {
                        nav.push(`/MainMenu/${params.entryID}/${params.name}/${params.token}`)
                    }}>
                        <IonIcon icon={home}></IonIcon>
                    </IonButton>
                    <IonTitle className="records-page-header">
                        Game Records
                    </IonTitle>
                    <IonButton slot="end" fill="clear" color="success" onClick={() => {
                        nav.push(`/Settings/${params.entryID}/${params.name}/${params.token}`)
                    }}>
                        <IonIcon icon={settingsOutline}></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>

                <IonCard hidden={false}>
                    <IonItem lines="none">
                        <IonCardTitle className="records-header">
                            <IonText color="primary">{params.name + "'s"}</IonText><IonText color="dark"> Yahtzee Records</IonText>
                        </IonCardTitle>
                        <IonButton slot="end" fill="clear" color={gameButtonColour} disabled={lockGameButton} hidden={hideYahtzeeRefresh} onClick={() => {
                            setHideYahtzeeRefresh(true)
                            setHideYahtzeeClose(false)
                            retreiveRecords(params.entryID, "yahtzeeRecords")
                        }}>
                            <IonIcon icon={caretDown}/>
                        </IonButton>
                        <IonButton slot="end" fill="clear" color={gameButtonColour} disabled={lockGameButton} hidden={hideYahtzeeClose} onClick={() => {
                            setHideYahtzeeRefresh(false)
                            setHideYahtzeeClose(true)
                            setHideYahtzeeRecords(true)
                            setLockViewButton(true)
                        }}>
                            <IonIcon icon={close}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="inset" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#1</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG1}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D1}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#1")
                            retrieveData(params.entryID, "yahtzeeRecords", "G1")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="inset" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#2</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG2}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D2}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#2")
                            retrieveData(params.entryID, "yahtzeeRecords", "G2")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="inset" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#3</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG3}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D3}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#3")
                            retrieveData(params.entryID, "yahtzeeRecords", "G3")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="inset" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#4</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG4}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D4}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#4")
                            retrieveData(params.entryID, "yahtzeeRecords", "G4")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="inset" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#5</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG5}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D5}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#5")
                            retrieveData(params.entryID, "yahtzeeRecords", "G5")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="inset" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#6</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG6}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D6}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#6")
                            retrieveData(params.entryID, "yahtzeeRecords", "G6")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="inset" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#7</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG7}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D7}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#7")
                            retrieveData(params.entryID, "yahtzeeRecords", "G7")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="inset" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#8</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG8}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D8}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#8")
                            retrieveData(params.entryID, "yahtzeeRecords", "G8")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="inset" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#9</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG9}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D9}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#9")
                            retrieveData(params.entryID, "yahtzeeRecords", "G9")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="none" className="records" hidden={hideYahtzeeRecords}>
                        <IonLabel>
                            <IonText color="dark">Game: </IonText><IonText color="primary">#10</IonText><IonText color="dark"> | Score: </IonText><IonText color="primary">{YG10}</IonText>
                            <div/>
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{D10}</IonText>
                        </IonLabel>
                        <IonButton slot="end" fill="clear" color={viewButtonColour} disabled={lockViewButton} onClick={() => {
                            setGameNum("#10")
                            retrieveData(params.entryID, "yahtzeeRecords", "G10")
                        }}>
                            <IonIcon icon={eye}/>
                        </IonButton>
                    </IonItem>
                </IonCard>

                <IonCard hidden={hideRecord}>
                    <IonItem lines="none">
                        <IonCardTitle className="records-header">
                            <IonText color="dark">{`Game ${gameNum}: `}</IonText><IonText color="primary">{`${finalScoreR}`}</IonText>
                        </IonCardTitle>
                        <IonButton slot="end" fill="clear" color="warning" onClick={() => {
                            setHideRecord(true)
                            setLockViewButton(false)
                            setLockGameButton(false)
                            setHideYahtzeeRecords(false)
                        }}>
                            <IonIcon icon={close}/>
                        </IonButton>
                    </IonItem>
                    <IonItem lines="none">
                            <IonText color="dark">Saved: </IonText><IonText color="primary">{`${date}`}</IonText>
                    </IonItem>
                </IonCard>

                <IonCard hidden={hideRecord}>
                    <IonItem lines="full">
                        <IonCardTitle className="records-header">
                            <IonText color="dark">Upper Scores:</IonText>
                        </IonCardTitle>
                        <IonCardTitle className="records-header" slot="end">
                            <IonText color="primary">{upperScoreR}</IonText>
                        </IonCardTitle>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/DiceOneCoin.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Ones: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{onesR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/DiceTwoMushroom.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Twos: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{twosR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/DiceThreeFireFlower.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Threes: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{threesR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/DiceFourYoshiEgg.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Fours: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{foursR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/DiceFiveStar.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Fives: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{fivesR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/DiceSixKoopaShell.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Sixes: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{sixesR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="none" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/YarzziDice.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Bonus: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{bonusR}</IonText>
                        </IonLabel>
                    </IonItem>
                </IonCard>

                <IonCard hidden={hideRecord}>
                    <IonItem lines="full">
                        <IonCardTitle className="records-header">
                            <IonText color="dark">Lower Scores: </IonText>
                        </IonCardTitle>
                        <IonCardTitle className="records-header" slot="end">
                            <IonText color="primary">{lowerScoreR}</IonText>
                        </IonCardTitle>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/ThreeOfAKind.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Three of a Kind: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{threeKindR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/FourOfAKind.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Four of a Kind: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{fourKindR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/FullHouse.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Full House: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{fullHouseR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/LowStraight.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Small Straight: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{smallStraightR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/HighStraight.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Large Straight: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{largeStraightR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/Yahtzee.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Yahtzee: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{yahtzeeR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="none" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/mario/Chance.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Chance: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{chanceR}</IonText>
                        </IonLabel>
                    </IonItem>
                </IonCard>

                <IonCard hidden={hideRecord}>
                    <IonItem lines="full">
                        <IonCardTitle className="records-header">
                            <IonText color="dark">Bonus Scores: </IonText>
                        </IonCardTitle>
                        <IonCardTitle className="records-header" slot="end">
                            <IonText color="primary">{bonusScoreR}</IonText>
                        </IonCardTitle>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/standard/Bonus.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Bonus Yahtzee #1: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{bonusYOR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="inset" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/standard/Bonus.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Bonus Yahtzee #2: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{bonusYTR}</IonText>
                        </IonLabel>
                    </IonItem>
                    <IonItem lines="none" className="score-records">
                        <IonAvatar slot="start">
                            <IonImg src="/assets/images/standard/Bonus.png"/>
                        </IonAvatar>
                        <IonLabel>
                            <IonText color="dark">Bonus Yahtzee #3: </IonText>
                        </IonLabel>
                        <IonLabel slot="end">
                            <IonText color="primary">{bonusYThR}</IonText>
                        </IonLabel>
                    </IonItem>
                </IonCard>

                <IonToast
                    isOpen={documentError}
                    onDidPresent={() => {}}
                    onDidDismiss={() => setDocumentError(false)}
                    message="There is no saved data in this slot."
                    icon={warning}
                    color="danger"
                    position="bottom"
                    buttons={[
                        {
                            text: "Ok",
                            role: "cancel",
                            handler: () => {}
                        }
                    ]}
                />
                
            </IonContent>
        </IonPage>
    );
};

export default Records;
