import {
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  IonItem,
  IonCardTitle,
  IonButton,
  IonInput,
  useIonRouter,
  IonImg,
  IonCardContent,
  IonIcon,
} from '@ionic/react';
import React, { useState } from 'react';
import { close } from 'ionicons/icons';
import './Home.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, getDoc, doc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDYhxOq7St4SaA3VNIxmk-Nx2va0KAFBKM",
  authDomain: "yahtzee-score-card.firebaseapp.com",
  projectId: "yahtzee-score-card",
  storageBucket: "yahtzee-score-card.appspot.com",
  messagingSenderId: "924619441287",
  appId: "1:924619441287:web:d1cd7c7e068038202ae422"
};

const app = initializeApp(firebaseConfig);

const Home: React.FC = () => {

  const nav = useIonRouter()
  const db = getFirestore(app)

  const [entryID, setEntryID] = useState<string>("")
  const [passcode, setPasscode] = useState<string>("")
  const [warningMsg, setWarningMsg] = useState<boolean>(true)

  async function login(entryID: string, passcode: string) {
    const docUserRef = doc(db, "users" , entryID)
    const docUser = await getDoc(docUserRef)
    if (docUser.exists()) {
      const userPasscode = docUser.data().passcode
      if (passcode === userPasscode) {
        const userToken = docUser.data().token
        const userName = docUser.data().name
        nav.push(`/MainMenu/${entryID}/${userName}/${userToken}`)
      } else {
        setEntryID("")
        setPasscode("")
        setWarningMsg(false)
      }
    } else {
      setEntryID("")
      setPasscode("")
      setWarningMsg(false)
    }
  }

  let IDColor: string

  if (entryID === "") {
    IDColor = "medium"
  } else {
    IDColor = "success"
  }

  let passcodeColor: string

  if (passcode === "") {
    passcodeColor = "medium"
  } else {
    passcodeColor = "success"
  }

  let buttonColor: string
  let invalidLogin: boolean

  if (entryID !== "" && passcode !== "") {
    invalidLogin = false
    buttonColor = "success"
  } else {
    invalidLogin = true
    buttonColor = "medium"
  }

  return (
    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle className="login-page-header">
            Welcome!
          </IonTitle>
          <IonTitle slot="end" className="login-build-number">
            Build <IonText color="danger">2.6.1 DEV</IonText>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonCard>
          <IonImg src="/assets/images/menu/LoginBanner.png"/>
        </IonCard>

        <IonCard>
          <IonItem lines="full">
            <IonCardTitle className="login-card-header" color="dark">
              Please Login:
            </IonCardTitle>
          </IonItem>
          <IonItem lines="full">
            <IonInput
              value={entryID}
              type="text"
              inputMode="text" 
              maxlength={50}
              placeholder="Enter Your Entry ID"
              color={IDColor}
              className=""
              spellCheck={false} 
              required={true} 
              onIonChange={e => setEntryID(e.detail.value!)}
            />
          </IonItem>
          <IonItem lines="full">
            <IonInput
              value={passcode}
              type="password"
              inputMode="text" 
              maxlength={50}
              placeholder="Enter Your Passcode"
              color={passcodeColor}
              className=""
              spellCheck={false} 
              required={true} 
              onIonChange={e => setPasscode(e.detail.value!)}
            />
          </IonItem>
          <IonItem lines="none">
            {/* <IonCardTitle className="login-card-header" color="dark">
              {nameTagText}
            </IonCardTitle> */}
            <IonButton slot="end" fill="clear" color={buttonColor} disabled={invalidLogin} onClick={() => {
              login(entryID, passcode)
            }}>
              Login
            </IonButton>
          </IonItem>
        </IonCard>

        <IonCard hidden={warningMsg}>
          <IonItem lines="full">
            <IonCardTitle className="login-card-header" color="warning">
              Warning!
            </IonCardTitle>
            <IonButton slot="end" fill="clear" color="warning" onClick={() => {
              setWarningMsg(true)
            }}>
              <IonIcon icon={close}></IonIcon>
            </IonButton>
          </IonItem>
            <IonCardContent className="login-card-content">
              <IonText color="dark">
                The Entry ID or Passcode you entered is incorrect. Please check your details and try again.
              </IonText>
            </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  );
};

export default Home;
