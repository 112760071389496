import {
  IonCard,
  IonContent,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  IonImg,
  IonItem,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonIcon,
  useIonRouter,
} from '@ionic/react';
import { logOutOutline, settingsOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import './MainMenu.css';

export interface GlobalParams {
  token: string
  entryID: string
  name: string
}

const MainMenu: React.FC = () => {

  const nav = useIonRouter()
  const params = useParams<GlobalParams>()

  var nameTag: string = params.name

  return (
    <IonPage id="home-page">
      <IonHeader>
        <IonToolbar>
          <IonButton slot="start" fill="clear" color="warning" href="/Home">
            <IonIcon icon={logOutOutline}></IonIcon>
          </IonButton>
          <IonTitle className="home-page-header">
            Welcome <IonText color="primary">{nameTag}</IonText>!
          </IonTitle>
          <IonButton slot="end" fill="clear" color="success" onClick={() => {
            nav.push(`/Settings/${params.entryID}/${params.name}/${params.token}`)
          }}>
            <IonIcon icon={settingsOutline}></IonIcon>
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>

        <IonCard>
          <IonImg src="/assets/images/menu/YahtzeeBanner.png"/>
          <IonItem lines="full">
            <IonCardTitle className="mario-yahtzee-card-header" color="dark">
              Yahtzee Score Card
            </IonCardTitle>
            <IonButton slot="end" fill="outline" color="success" onClick={() => {
              nav.push(`/Yahtzee/YahtzeeScoreCard/${params.entryID}/${params.name}/${params.token}`)
            }}>
              Play
            </IonButton>
          </IonItem>
          <IonCardContent>
            <IonText color="dark">
              A Yahtzee score card that gives you an electronic way to keep track of your game. Contains two themes "Super Mario" and "Standard".
            </IonText>
          </IonCardContent>
        </IonCard>

        <IonCard>
          <IonImg src="/assets/images/menu/QuestionMark.png"/>
          <IonItem lines="full">
            <IonCardTitle className="settings-card-header" color="dark">
              Game Records
            </IonCardTitle>
            <IonButton slot="end" fill="outline" color="success" onClick={() => {
              nav.push(`/Records/${params.entryID}/${params.name}/${params.token}`)
            }}>
              View
            </IonButton>
          </IonItem>
          <IonCardContent>
            <IonText color="dark">
              View saved records of your previous games.
            </IonText>
          </IonCardContent>
        </IonCard>

      </IonContent>
    </IonPage>
  );
};

export default MainMenu;
